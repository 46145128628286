import moment from 'moment';
moment.locale('pt-br');

export const isAuthenticated = () => {
    var momento = moment();
    // // console.log('data atual', momento.tz.setDefault("America/Sao_Paulo").format('Y-M-D H:I'))
    let status = localStorage.getItem('fb70004dd183ab753b99cd98543eb6c5_statusLogin');
    if (status == 'false') {
        return false;
    } else if (status == 'true' || status == true) {
        // // console.log('moment: ' + moment().format('Y-M-D'))
        // let momentIsAfter = moment().isAfter(localStorage.getItem('fb70004dd183ab753b99cd98543eb6c5_validuntil'));
        // // console.log('momentIsAfter', momentIsAfter)
        // // console.log('momentFrontEnd', moment().format('Y-M-D HH:mm:ss'))
        // // console.log('momentFrontEnd', moment(localStorage.getItem('fb70004dd183ab753b99cd98543eb6c5_validuntil')).format('Y-M-D HH:mm:ss'))
        // if (momentIsAfter == true) {
        //     return false;
        // } else {
        //     return true;
        // }
        return true;
    } else {
        return false;
    }
};

export default isAuthenticated;