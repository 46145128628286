import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import styled from "styled-components";
import api from '../../../services/api';
import { Template } from '../../components/template/index';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
// Icones
import LaunchIcon from '@material-ui/icons/Launch';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
// Icones Novos 
import { BiUserCircle } from 'react-icons/bi';
import { FiUsers } from 'react-icons/fi';
import { AiOutlineCluster } from 'react-icons/ai';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IoAddCircle, IoCheckmarkCircleOutline, IoRemoveCircleOutline, IoAddCircleOutline } from 'react-icons/io5';
import Alert from '@material-ui/lab/Alert';

export default function Usuarios({ history }) {

    if (localStorage.getItem('fb70004dd183ab753b99cd98543eb6c5_profile') == 'public') {
        // history.push('/dashboard/nao-possui-permissao')
        window.location.href = '/dashboard/nao-possui-permissao';
    }

    const [usuarioSelecionado, setUsuarioSelecionado] = useState('');

    const [listaDeUsuarios, setListaDeUsuarios] = useState(
        <div className="text-center p-3 mt-5">
            <CircularProgress color="primary" />
            <p className="p-2 text-center fontSize17">
                Buscando registro de usuários...
            </p>
        </div>
    );
    const [listaDePermissoes, setListaDePermissoes] = useState(
        <div className="text-center p-3 mt-5">
            <CircularProgress color="primary" />
            <p className="p-2 text-center fontSize17">
                Aguardando selecionar usuário
            </p>
        </div>
    );

    const [mensagemErro, setMensagemErro] = React.useState();

    useEffect(() => {
        const listarUsuarios = async (id) => {
            const resposta = await api({
                method: 'get',
                url: '/v1/User/' + id,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('fb70004dd183ab753b99cd98543eb6c5_token')}`,
                }
            }).then(function (response) {
                return response.data
            }).catch(function (error) {
                console.log(error.status)
                return false
            });
            if (resposta.length > 0) {
                let lista = (
                    resposta.map(
                        informacao => (
                            <Box display="flex" m={0} alignItems="center" key={informacao.id} className="itemUser">
                                <Box p={1} bgcolor="">
                                    <BiUserCircle className="iconMedium" />
                                </Box>
                                <Box p={1} flexGrow={1}>
                                    <NomeUsuario>
                                        {informacao.name}
                                    </NomeUsuario>
                                </Box>
                                <Box p={1} className="text-center">
                                    <Button variant="contained" color="primary" size="small" onClick={() => {
                                        setUsuarioSelecionado(`- ${informacao.name}`)
                                        selecionaUsuario(informacao.id, informacao.profileid)
                                    }}>
                                        <LaunchIcon />
                                    </Button>
                                </Box>
                            </Box>
                        )
                    )
                );

                setListaDeUsuarios(lista)
            }

        }
        listarUsuarios(1)
    }, [])

    const selecionaUsuario = async (id, profileid) => {
        setListaDePermissoes(
            <div className="text-center p-3 mt-5">
                <CircularProgress color="primary" />
                <p className="p-2 text-center fontSize17">
                    Buscando Permissões
                </p>
            </div>
        )
        let listaPermissoesAtualizada = [];
        const listaDePermissoes = await api({
            method: 'get',
            url: '/v1/Permission',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('fb70004dd183ab753b99cd98543eb6c5_token')}`,
            }
        }).then(function (response) {
            return response.data
        }).catch(function (error) {
            console.log(error.status)
            return false
        });
        if (listaDePermissoes.length > 0) {
            for (let index = 0; index < listaDePermissoes.length; index++) {
                const informacao = listaDePermissoes[index];
                const permissao = await api({
                    method: 'get',
                    url: `/v1/Access/${id}/?permissionid=${informacao.id}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('fb70004dd183ab753b99cd98543eb6c5_token')}`,
                    }
                }).then(function (response) {
                    return response.data
                }).catch(function (error) {
                    return false
                });
                if (permissao != false) {
                    if (permissao[0].grant == 'T') {
                        listaPermissoesAtualizada.push({
                            'id': informacao.id,
                            'description': informacao.description,
                            'adddate': informacao.adddate,
                            'changedate': informacao.description,
                            'grant': 'T',
                        });
                    } else {
                        await listaPermissoesAtualizada.push({
                            'id': informacao.id,
                            'description': informacao.description,
                            'adddate': informacao.adddate,
                            'changedate': informacao.description,
                            'grant': 'F',
                            'exist': 'Y'
                        });
                    }
                } else {
                    await listaPermissoesAtualizada.push({
                        'id': informacao.id,
                        'description': informacao.description,
                        'adddate': informacao.adddate,
                        'changedate': informacao.description,
                        'grant': 'F',
                        'exist': 'N'
                    });
                }
            };
            let lista = (
                <>
                    {
                        [].map.call(listaPermissoesAtualizada, function (informacao) {
                            let button = '';
                            console.log(`grant: ${informacao.grant}`)
                            if (informacao.grant == 'T') {
                                button = (
                                    <>
                                        {/* <IconButton variant="contained" color="primary" size="medium">
                                            <IoCheckmarkCircleOutline color="primary" />
                                        </IconButton > */}
                                        <IconButton variant="contained" color="primary" size="medium" style={{ color: '#e57373' }} onClick={() => removerPermissao(id, profileid, informacao.id, 'F')}>
                                            <IoRemoveCircleOutline color="primary" />
                                        </IconButton >
                                    </>

                                );
                            } else {
                                if (informacao.exist == 'Y') {
                                    button = (
                                        <IconButton variant="contained" color="secondary" size="medium" onClick={() => removerPermissao(id, profileid, informacao.id, 'T')}>
                                            <IoAddCircle color="secondary" />
                                        </IconButton >
                                    );
                                } else {
                                    button = (
                                        <IconButton variant="contained" color="secondary" size="medium" onClick={() => atribuirPermissao(id, profileid, informacao.id)}>
                                            <IoAddCircle color="secondary" />
                                        </IconButton >
                                    );
                                }
                            }
                            return (
                                <Box display="flex" m={0} alignItems="center" key={informacao.id} className="itemUser">
                                    <Box p={1} bgcolor="">
                                        <BiUserCircle className="iconMedium" />
                                    </Box>
                                    <Box p={1} flexGrow={1}>
                                        <NomeUsuario>
                                            {informacao.description}
                                        </NomeUsuario>
                                    </Box>
                                    <Box p={1}>
                                        {button}
                                    </Box>
                                </Box>
                            );
                        })
                    }
                </>
            )
            setListaDePermissoes(lista)
        }
    }

    const atribuirPermissao = async (Userid, Profileid, Permissionid) => {
        setListaDePermissoes(
            <div className="text-center p-3 mt-5">
                <CircularProgress color="primary" />
                <p className="p-2 text-center fontSize17">
                    Adicionando permissão
                </p>
            </div>
        )
        // console.log(JSON.stringify({
        //     'Userid': Userid,
        //     'Profileid': Profileid,
        //     'Permissionid': Permissionid,
        //     'Grant': 'T'
        // }))
        let resposta = await api({
            method: 'post',
            url: '/v1/Access',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('fb70004dd183ab753b99cd98543eb6c5_token')}`,
            },
            data: {
                'Userid': Userid,
                'Profileid': Profileid,
                'Permissionid': Permissionid,
                'Grant': 'T'
            }
        }).then(async function (response) {
            selecionaUsuario(Userid, Profileid)
        }).catch(function (error) {
            console.log(error)
            // removerPermissao(Userid, Profileid, Permissionid, 'T')
            return error
        });
        if (resposta != undefined && (
            resposta?.response?.status == 400 ||
            resposta?.response?.status == 401 ||
            resposta?.response?.status == 403
        )) {
            let status = resposta?.status === undefined ? resposta?.response?.status : resposta?.response?.status;
            let mensagemErro = '';
            if (
                resposta?.response?.data?.suggestion === undefined &&
                resposta?.response?.data?.suggestion === '' &&
                status !== 403
            ) {
                mensagemErro = 'Ops! Ocorreu um erro';
            } else if (status === 403) {
                mensagemErro = 'Ops! Você não tem permissão';
            } else if (
                resposta?.response?.data?.suggestion !== undefined &&
                resposta.response.data.suggestion !== '' &&
                resposta?.response?.data?.suggestion?.length > 0
            ) {
                mensagemErro = `
                    ${(resposta.response.data.suggestion ??  resposta.response.data.suggestion)} 
                    ${(resposta.response.data.description ??  resposta.response.data.description)} 
                `;
            } else {
                mensagemErro = 'Ops! Ocorreu um erro'
            }
            setMensagemErro(<Alert severity="warning">{mensagemErro}</Alert>);
            selecionaUsuario(Userid, Profileid)
        } else {
            setMensagemErro('')
        }
    }

    const removerPermissao = async (Userid, Profileid, Permissionid, Grant) => {
        setListaDePermissoes(
            <div className="text-center p-3 mt-5">
                <CircularProgress color="primary" />
                <p className="p-2 text-center fontSize17">
                    Adicionando permissão
                </p>
            </div>
        )
        await api({
            method: 'put',
            url: '/v1/Access',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('fb70004dd183ab753b99cd98543eb6c5_token')}`,
            },
            data: {
                'Userid': Userid,
                'Profileid': Profileid,
                'Permissionid': Permissionid,
                'Grant': Grant
            }
        }).then(async function (response) {
            selecionaUsuario(Userid, Profileid)
        }).catch(function (error) {
            return false
        });
    }

    return (
        <Template titulo={'Usuários'}>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6} md={6} sm={12}>
                    <Paper elevation={1}>
                        <TituloCard>
                            <Box display="flex" p={0} m={0} alignItems="center" className="text-primary">
                                <Box p={0} bgcolor="">
                                    <FiUsers className="iconLarge" />
                                </Box>
                                <Box p={0} flexGrow={1}>
                                    <Titulo>
                                        Usuários
                                    </Titulo>
                                </Box>
                                <Box p={0} bgcolor="">
                                    <IconButton variant="contained" color="primary" component={Link} to="/dashboard/usuarios/cadastrar">
                                        <IoAddCircleOutline />
                                    </IconButton >
                                </Box>
                            </Box>
                        </TituloCard>
                        {listaDeUsuarios}
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={6} md={6} sm={12}>
                    <Paper elevation={1}>
                        <TituloCard>
                            <Box display="flex" p={0} m={0} alignItems="center" className="text-primary">
                                <Box p={0} bgcolor="">
                                    <AiOutlineCluster className="iconLarge" />
                                </Box>
                                <Box p={0} flexGrow={1}>
                                    <Titulo>
                                        Permissão do Usuário {(usuarioSelecionado)}
                                    </Titulo>
                                </Box>
                            </Box>
                        </TituloCard>
                        {mensagemErro}
                        {listaDePermissoes}
                    </Paper>
                </Grid>
            </Grid>
        </Template >
    );
}

const TituloCard = styled.div`
    padding: 5px 20px;
    margin: 0px;
    /* margin-bottom: 1rem; */
    text-align: left;
    background-color: #f8f8f9;
    font-size: 27px;
    font-weight: 500;
    border-bottom: #ccc solid 1px;
    `;

const Titulo = styled.h1`
    padding: 0px 10px;
    margin: 0px;
    text-align: left;
    font-size: 22px;
    `;


const Paragrafo = styled.h1`
    padding: 2.5px 5px;
    margin: 0px;
    text-align: left;
    font-size: 17px;
    font-weight: 500px;
`;

const NomeUsuario = styled.h5`
    font-size: 17px;
    padding: 0px;
    margin: 0px;
    font-weight: 500;
    `;

const LinhaDivisao = styled.div`
    padding: 0px;
    margin: 0px;
    height: 1px;
    `;