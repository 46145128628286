import React, { useState } from 'react';
import api from '../../services/api';
import styled from "styled-components";
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import logo from './../../assets/images/logo.png';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        marginTop: 50
    },
    paper: {
        height: 140,
        width: 100,
        padding: 20
    },
    control: {
        padding: theme.spacing(5),
    },
    itemAlignRight: {
        textAlign: 'right'
    }
}));

export default function Login({ history }) {
    document.title = 'Login - Dashboard';
    const refEmail = React.useRef(null);
    const refSenha = React.useRef(null);
    const [mensagem, setMensagem] = useState('');
    const classes = useStyles();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMensagem(
            <div className="text-center p-3 mt-5">
                <CircularProgress color="primary" />
                <p className="p-2 text-center fontSize17">
                    Validando dados do usuário
                </p>
            </div>
        )
        const resposta = await api({
            method: 'post',
            url: '/v1/user/authorize',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                "email": refEmail.current.value,
                "password": refSenha.current.value
            }
        }).then(function (response) {
            setMensagem('');
            // console.log(response)
            return response
        }).catch(function (error) {
            // console.log(error)
            // console.log(error.status)
            setMensagem(
                // <Alert severity="error">
                //     <strong>Ops!</strong> {error.message}
                // </Alert>
                <Alert severity="error">
                    <strong>Ops!</strong> Ocorreu um erro.
                </Alert>
            )
            // console.log('')
            return false
        });
        // console.log('')
        // // console.log(resposta)
        if (resposta) {
            if (resposta.data != undefined && resposta.data.length != 0) {
                if (resposta != undefined && resposta.data.length != 0) {
                    let dados = resposta.data;
                    if (dados.status == false) {
                        localStorage.setItem('fb70004dd183ab753b99cd98543eb6c5_statusLogin', false);
                        localStorage.setItem('fb70004dd183ab753b99cd98543eb6c5_token', null);
                        localStorage.setItem('fb70004dd183ab753b99cd98543eb6c5_profile', null);
                        localStorage.setItem('fb70004dd183ab753b99cd98543eb6c5_userid', null);
                        localStorage.setItem('fb70004dd183ab753b99cd98543eb6c5_clientid', null);
                        localStorage.setItem('fb70004dd183ab753b99cd98543eb6c5_validuntil', null);
                        localStorage.setItem('fb70004dd183ab753b99cd98543eb6c5_user', null);
                        // console.log(dados)
                        setMensagem(
                            <div className="alert alert-warning mt-2" role="alert">
                                <strong>Ops!</strong> Dados inválidos
                            </div>
                        )
                    } else {
                        localStorage.setItem('fb70004dd183ab753b99cd98543eb6c5_statusLogin', true);
                        localStorage.setItem('fb70004dd183ab753b99cd98543eb6c5_token', dados.token);
                        localStorage.setItem('fb70004dd183ab753b99cd98543eb6c5_profile', dados.profile);
                        localStorage.setItem('fb70004dd183ab753b99cd98543eb6c5_userid', dados.userid);
                        localStorage.setItem('fb70004dd183ab753b99cd98543eb6c5_clientid', dados.clientid);
                        localStorage.setItem('fb70004dd183ab753b99cd98543eb6c5_validuntil', dados.validuntil);
                        localStorage.setItem('fb70004dd183ab753b99cd98543eb6c5_user', JSON.stringify(dados.user));
                        history.push('/dashboard/home')
                    }
                } else {
                    setMensagem(
                        <div className="alert alert-warning mt-2" role="alert">
                            <strong>Ops!</strong> Dados inválidos
                        </div>
                    )
                }
            } else {
                setMensagem(
                    <Alert severity="error">
                        <strong>Ops!</strong> Ocorreu um erro.
                    </Alert>
                )
            }
        }

    }

    const Logo = styled.div`
    padding: 5px 20px;
    margin: auto;
    max-width: 100%;
    text-align: center;
    `;

    const inputPropsEmail = {
        ref: refEmail
    }

    const inputPropsSenha = {
        ref: refSenha
    }
    return (
        <>
            {/* <CssBaseline /> */}
            <Container maxWidth="sm" className={classes.container}>
                <Paper className={classes.control}>
                    <Logo>
                        <img src='/assets/images/logo.png' style={{maxWidth: '100%'}} />
                    </Logo>
                    {mensagem}
                    <br />
                    <form className={classes.root} onSubmit={handleSubmit} noValidate autoComplete="off">
                        <Grid container spacing={3} >
                            <Grid item xs={12} border={1}>
                                <TextField
                                    id="outlined-basic"
                                    label="Login"
                                    variant="outlined"
                                    inputProps={inputPropsEmail}
                                    fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-password-input"
                                    label="Senha"
                                    type="password"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    inputProps={inputPropsSenha}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" fullWidth type="submit">
                                    Entrar
                                </Button>
                            </Grid>
                        </Grid>
                        {/* <Grid container spacing={3} >
                            <Grid item xs={12}>
                                <div className={classes.itemAlignRight}>
                                    <Link href="/esqueci-minha-senha" color="secondary" className={classes.itemAlignRight}>
                                        Esqueci minha senha
                                    </Link>
                                </div>
                            </Grid>
                        </Grid> */}
                    </form>
                </Paper>
            </Container>
        </>
    )
}

export function Sair({ history }) {
    localStorage.setItem('statusLogin', false);
    localStorage.setItem('token', null);
    localStorage.setItem('profile', null);
    localStorage.setItem('userid', null);
    localStorage.setItem('clientid', null);
    localStorage.setItem('validuntil', null);
    localStorage.setItem('user', null);
    history.push('/login');
}