import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styled from "styled-components";
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// meus icones
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined';
import GroupIcon from '@material-ui/icons/Group';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import Button from '@material-ui/core/Button';
import { apiFarmix } from '../../../services/api';
import { FaHome, FaMedkit, FaUserFriends, FaListUl } from "react-icons/fa";




// export default function Template(props) {
export const Template = (props, history) => {
    const drawerWidth = 240;
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            textAlign: 'left'
        },
    }));
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const [listaCards, setListaCards] = React.useState();
    React.useEffect(() => {
        const listarLayouts = async () => {
            var resposta = await apiFarmix('get', '/v1/Order/cards', null);
            if (resposta.data) {
                resposta = resposta.data.results;
                localStorage.setItem('cardsHome', JSON.stringify(resposta));
                setListaCards(
                    <>
                        {
                            [].map.call(resposta, function (informacao, index) {
                                // console.log('informacao', informacao)
                                return (
                                    <ListItem button component={Link} to={`/dashboard/por-cliente/${informacao.layoutid}/${informacao.ol}`} key={index}>
                                        <ListItemIcon>
                                            <FaMedkit style={{ fontSize: 28 }} />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {informacao.ol}
                                        </ListItemText>
                                    </ListItem>
                                );
                            })
                        }
                    </>
                );
            }
        }
        listarLayouts();
    }, [])

    const listaOls = React.useRef(null);

    const exibirListaOLs = () => {
        listaOls.current.classList.toggle('none');
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* <Typography variant="h6" noWrap>
                        
                    </Typography> */}
                    <div style={{ width: '100%', display: 'flex', justifyItems: 'center', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', justifyItems: 'center' }}>
                            <Button style={{ backgroundColor: 'transparent', color: '#fff', maxWidth: '25px' }} onClick={
                                () => window.history.back()
                            }>
                                <FaLongArrowAltLeft style={{ fontSize: '19px' }} />
                            </Button>
                            <Typography variant="h6" noWrap>{props.titulo}</Typography>
                        </div>
                        <div>
                            {props.nomeOL == undefined ? '' : <Button variant="contained" style={{ backgroundColor: '#fff', color: '#212121' }} disabled>{props.nomeOL}</Button>}
                            {/* <Box
                                display="flex"
                                flexWrap="wrap"
                                alignItems={'center'}
                                alignContent={'end'}
                                >
                                <Box>
                                    <Typography variant="h6" noWrap>{props.nomeOL}</Typography>
                                </Box>
                            </Box> */}
                        </div>
                        
                        <div>
                            Bem vindo(a) <strong>{localStorage.getItem('fb70004dd183ab753b99cd98543eb6c5_user').replace('"', '').replace('"', '')}</strong>
                        </div>
                    </div>
                </Toolbar>
            </AppBar >
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>

                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <ListItem button component={Link} to="/dashboard/home">
                        <ListItemIcon>
                            <FaHome style={{ fontSize: 28 }} />
                        </ListItemIcon>
                        <ListItemText>
                            Inicio
                        </ListItemText>
                    </ListItem>
                </List>
                <Divider />
                <div onClick={() => exibirListaOLs()}>
                    <List>
                        <ListItem button component={Link} to="/dashboard/home">
                            <ListItemIcon>
                                <FaListUl style={{ fontSize: 24 }} />
                            </ListItemIcon>
                            <ListItemText>
                                Ols
                            </ListItemText>
                        </ListItem>
                    </List>
                </div>
                <div className="none" ref={listaOls}>
                    <List>
                        {listaCards}
                    </List>
                </div>
                <Divider />
                <List>
                    <ListItem button component={Link} to="/dashboard/usuarios">
                        <ListItemIcon>
                            <FaUserFriends style={{ fontSize: 28 }} />
                        </ListItemIcon>
                        <ListItemText>
                            Usuarios
                        </ListItemText>
                    </ListItem>
                    <ListItem button component={Link} to="/sair">
                        <ListItemIcon>
                            <CancelOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Sair
                        </ListItemText>
                    </ListItem>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div >
    );
}

const ButtonVoltar = styled.button`
display: block;
border: solid 1px #bdc3c7;
background-color: transparent!important;
color: #fff;
padding-top: 10px;
padding-bottom: 5px;
padding-left: 10px;
padding-right: 10px;
margin-right: 10px;
border-radius: .25rem;
cursor: pointer;
&:hover{
    border: solid 1px rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.4)!important;
    color: #fff;
    transition: 0.6s;
}
`;