import axios from "axios";
const https = require("https");

const agent = new https.Agent({
  rejectUnauthorized: false,
});

export const api = axios.create({
  baseURL: "https://auth.stin.com.br/api",
  httpsAgent: agent,
});
// Prod: 8aobk0vyr9.execute-api.us-east-2.amazonaws.com/Prod
// HML: ol.stin.com.br
export const apiFarmix = async (method, url, data) => {
  let resposta = false;
  // console.log('https://ol.stin.com.br/farmix/api' + url)
  if (method === "get") {
    resposta = await api({
      method: method,
      url:
        "https://8aobk0vyr9.execute-api.us-east-2.amazonaws.com/Prod/farmix/api" +
        url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "fb70004dd183ab753b99cd98543eb6c5_token"
        )}`,
      },
      data: data,
    })
      .then(function (response) {
        // // console.log('response')
        // // console.log(response)
        // // console.log('response')
        return response;
      })
      .catch(function (error) {
        // // console.log('error')
        // // console.log(JSON.stringify(error))
        return error;
      });
  } else if (method === "post" || method === "put") {
    if (data !== undefined) {
      resposta = await api({
        method: method,
        url:
          "https://8aobk0vyr9.execute-api.us-east-2.amazonaws.com/Prod/farmix/api" +
          url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "fb70004dd183ab753b99cd98543eb6c5_token"
          )}`,
        },
        data: data,
      })
        .then(function (response) {
          // // console.log('response')
          // // console.log(response)
          // // console.log('response')
          return response;
        })
        .catch(function (error) {
          // // console.log('error')
          // // console.log(JSON.stringify(error))
          return error;
        });
    } else {
      resposta = await api({
        method: method,
        url:
          "https://8aobk0vyr9.execute-api.us-east-2.amazonaws.com/Prod/farmix/api" +
          url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            "fb70004dd183ab753b99cd98543eb6c5_token"
          )}`,
        },
      })
        .then(function (response) {
          // // console.log('response')
          // // console.log(response)
          // // console.log('response')
          return response;
        })
        .catch(function (error) {
          // // console.log('error')
          // // console.log(JSON.stringify(error))
          return error;
        });
    }
  }
  return resposta;
};

export default api;
