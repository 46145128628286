import React from "react";
import styled from "styled-components";
import { Template } from "../../components/template/index";
import { FiAlertCircle } from "react-icons/fi";

export default function Home() {
  document.title = "Permissão Negada - Dashboard";
  return (
    <Template titulo={"Permissão Negada"}>
      <Div>
        <FiAlertCircle style={{ color: "#fff", fontSize: "64px" }} />
        <Titulo>Permissão Negada</Titulo>
      </Div>
    </Template>
  );
}

const Div = styled.div`
  margin: auto;
  padding: 20px 10px;
  max-width: 512px;
  background-color: #e57373;
  text-align: center;
  /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
  border-radius: 0.25rem;
`;

const Titulo = styled.div`
  margin: auto;
  padding: 20px 10px;
  max-width: 100%;
  text-align: center;
  color: #fff;
  font-size: 19px;
`;
