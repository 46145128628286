import React, { useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import styled from "styled-components";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { FaDotCircle } from 'react-icons/fa';
import { apiFarmix } from '../../../services/api';
import CircularProgress from '@material-ui/core/CircularProgress';

export const ModalReenvio = ({ openModalReenvio, setOpenModalReenvio, uuid }) => {
    const listaOpcoes = (
        <>
            <Box
                display="flex"
                alignItems="center"
                style={{
                    padding: 5,
                    marginBottom: 5,
                    border: 'solid 2px #ecf0f1',
                    borderRadius: 5,
                    cursor: 'pointer'
                }}
                onClick={() => distaparReenvio(uuid, 1)}>
                <Box p={1}>
                    <div
                        style={{
                            paddingTop: 5,
                            cursor: 'pointer'
                        }}>
                        <FaDotCircle className="text-secondary" style={{ fontSize: 18 }} />
                    </div>
                </Box>
                <Box p={1} flexGrow={1}>
                    <div
                        style={{
                            paddingTop: 0,
                            paddingLeft: 5
                        }}>
                        Arquivo de Pedido
                    </div>
                </Box>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                style={{
                    padding: 5,
                    marginBottom: 5,
                    border: 'solid 2px #ecf0f1',
                    borderRadius: 5,
                    cursor: 'pointer'
                }}
                onClick={() => distaparReenvio(uuid, 2)}>
                <Box p={1}>
                    <div
                        style={{
                            paddingTop: 5,
                            cursor: 'pointer'
                        }}>
                        <FaDotCircle className="text-secondary" style={{ fontSize: 18 }} />
                    </div>
                </Box>
                <Box p={1} flexGrow={1}>
                    <div
                        style={{
                            paddingTop: 0,
                            paddingLeft: 5
                        }}>
                        Arquivo de NF
                    </div>
                </Box>
            </Box>
        </>
    )
    useEffect(() => {
        if (openModalReenvio === true) {
            setModalBody(listaOpcoes)
            setMsgModal('')
            // console.log('uuid', uuid)
        }
    }, [openModalReenvio])

    // const [open, setOpen] = React.useState(openModal);
    const [msgModal, setMsgModal] = React.useState(false);
    const [modalBody, setModalBody] = React.useState(listaOpcoes);
    const [modalBtnAcao, setModalBtnAcao] = React.useState(
        <div>
            <Button variant="contained" style={{ backgroundColor: '#ECF0F1' }} size="small" onClick={() => handleClose()}>
                Cancelar
            </Button>
        </div>
    );
    const handleClose = () => {
        setOpenModalReenvio(false);
    };
    const distaparReenvio = async (uuid, id) => {
        // try {
        setModalBtnAcao('');
        let resposta = await apiFarmix('put', `/v1/Order/resend/${uuid}/${id}`);
        setModalBody(
            <>
                <div style={{ textAlign: 'center', padding: '10px', marginTop: '10px' }}>
                    <CircularProgress color="secondary" />
                    <h3 style={{ textAlign: 'center', padding: '10px', marginTop: '10px' }}>
                        Processando Solciitação pedido...
                    </h3>
                </div>
            </>
        )
        if (resposta != undefined && (
            resposta?.status == 400 || resposta?.response?.status == 400 ||
            resposta?.status == 401 || resposta?.response?.status == 401 ||
            resposta?.status == 403 || resposta?.response?.status == 403
        )) {
            setModalBody('');
            let status = resposta?.status === undefined ? resposta?.response?.status : resposta?.response?.status;
            let mensagemErro = '';
            if (
                resposta?.response?.data?.suggestion === undefined &&
                resposta?.response?.data?.suggestion === '' &&
                status !== 403
            ) {
                mensagemErro = 'Ops! Ocorreu um erro';
            } else if (status === 403) {
                mensagemErro = 'Ops! Você não tem permissão';
            } else if (
                resposta?.response?.data?.suggestion !== undefined &&
                resposta.response.data.suggestion !== '' &&
                resposta?.response?.data?.suggestion?.length > 0
            ) {
                mensagemErro = `
                    ${(resposta.response.data.suggestion ??  resposta.response.data.suggestion)} 
                    ${(resposta.response.data.description ??  resposta.response.data.description)} 
                `;
            } else {
                mensagemErro = 'Ops! Ocorreu um erro'
            }
            setMsgModal(
                <Alert severity="warning" style={{ marginBottom: 25 }}>
                    {mensagemErro}
                </Alert>
            );
            setModalBtnAcao(
                <div>
                    <Button variant="contained" style={{ backgroundColor: '#ECF0F1' }} size="small" onClick={() => handleClose()}>
                        Fechar
                    </Button>
                </div>
            );
        } else {
            setModalBody('');
            setMsgModal(
                <Alert severity="success">Parabéns! Reenvio feito com sucesso.</Alert>
            )
            setModalBtnAcao(
                <div>
                    <Button variant="contained" style={{ backgroundColor: '#ECF0F1' }} size="small" onClick={() => handleClose()}>
                        Fechar
                    </Button>
                </div>
            );
        }
        // } catch (error) {
        //     setModalBody('');
        //     setMsgModal(
        //         <Alert severity="warning" style={{ marginBottom: 25 }}>
        //             Ops! Ocorreu um erro, tente novamente mais tarde
        //         </Alert>
        //     );
        //     setModalBtnAcao(
        //         <div>
        //             <Button variant="contained" style={{ backgroundColor: '#ECF0F1' }} size="small" onClick={() => handleClose()}>
        //                 Fechar
        //             </Button>
        //         </div>
        //     );
        // }
    }
    return (
        <Modal
            open={openModalReenvio}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <ModalContent>
                <Paper style={{ borderRadius: '10px' }}>
                    <ModalTitle>
                        Reenvio de Retorno de atendimento ou retorno de arquivo de NF
                    </ModalTitle>
                    <ModalBody>
                        <div>{msgModal}</div>
                        {modalBody}
                        <ModalFooter>
                            {modalBtnAcao}
                        </ModalFooter>
                    </ModalBody>
                </Paper>
            </ModalContent>
        </Modal>
    );
}


const ModalContent = styled.div`
    max-width: 512px;
    margin: auto;
    margin-top: 10%;
    border: solid 1.5px rgba(0,0,0,0.1);
    border-radius: 5px;
`;

const ModalBody = styled.div`
    background-color: #fff;
    padding: 20px 20px;
    `;

const ModalTitle = styled.div`
    background-color: #4791db;
    color: #fff;
    text-align: center;
    /* border-radius: 5px; */
    padding: 10px 10px;
    font-size: 22px;
    `;

const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    margin-top: 10px;
    &>div {
        padding: 2px
    }
    `;