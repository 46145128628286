import React from "react";
import styled from "styled-components";
import api, { apiFarmix } from "../../../services/api";
import { Template } from "../../components/template/index";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FaMedkit } from "react-icons/fa";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
// import Checkbox from '@material-ui/core/Checkbox';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import {
  FaEraser,
  FaExternalLinkAlt,
  FaTrash,
  FaDotCircle,
  FaFileUpload,
} from "react-icons/fa";
import {
  mascaraDocumento,
  mascaraData,
  retornandoSomenteInteiro,
} from "./../../../lib";
import Modal from "@material-ui/core/Modal";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import Parser from "html-react-parser";
import { ModalReenvio } from "./../../components/modal";

function createData(
  id,
  codfilial,
  numpedol,
  numpederp,
  dtimportacao,
  dtretorno,
  dtpedido,
  dtfaturamento,
  obs,
  layout,
  uuid
) {
  return {
    id,
    codfilial,
    numpedol,
    numpederp,
    dtimportacao,
    dtretorno,
    dtpedido,
    dtfaturamento,
    obs,
    layout,
    uuid,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "id", numeric: true, disablePadding: true, label: "ID " },
  { id: "codfilial", numeric: true, disablePadding: false, label: "Filial " },
  { id: "numpedol", numeric: true, disablePadding: false, label: "Nº OL " },
  {
    id: "numpederp",
    numeric: true,
    disablePadding: false,
    label: "Nº Pedido ERP ",
  },
  {
    id: "dtimportacao",
    numeric: false,
    disablePadding: false,
    label: "Dt Importação ",
  },
  {
    id: "dtretorno",
    numeric: false,
    disablePadding: false,
    label: "Dt Retorno ",
  },
  {
    id: "dtpedido",
    numeric: false,
    disablePadding: false,
    label: "Dt Pedido ",
  },
  {
    id: "dtfaturamento",
    false: true,
    disablePadding: false,
    label: "Dt Faturamento ",
  },
  { id: "obs", numeric: false, disablePadding: false, label: "Observação " },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span classID={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
          <TableSortLabel></TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel></TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel></TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(
        classes.root
        //     , {
        //     [classes.highlight]: numSelected > 0,
        // }
      )}
    >
      {/* {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Lista de Pedidos
                </Typography>
            )} */}
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Lista de Pedidos
      </Typography>

      {/* {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )} */}
    </Toolbar>
  );
};

// EnhancedTableToolbar.propTypes = {
//     numSelected: PropTypes.number.isRequired,
// };

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function Home(props) {
  document.title = "Inicio - Dashboard";
  const [textoPesquisa, setTextoPesquisa] = React.useState("");

  // const [listaCards, setListaCards] = React.useState(
  //     <>
  //         <div className="m-auto">
  //             <div className="text-center p-3 mt-5">
  //                 <CircularProgress color="primary" />
  //                 <p className="p-2 text-center fontSize17">
  //                     Carregando...
  //                 </p>
  //             </div>
  //         </div>
  //     </>
  // )

  const listarPedidosPendentes = async () => {
    let resposta = await apiFarmix("get", `/v1/Order/pendency`, null);
    if (resposta.data) {
      let linhasNovas = [];
      let contador = 0;
      resposta.data.results.forEach((item) => {
        contador++;
        if (contador == 1) {
          // console.log('item', JSON.stringify(item))
        }
        if (
          item.dtimportacao == "" ||
          moment(item.dtimportacao).format("DD/MM/Y HH:mm").toString() ==
            "Invalid date"
        ) {
          item.dtimportacao = " - ";
        } else {
          item.dtimportacao = moment(item.dtimportacao)
            .format("DD/MM/Y HH:mm")
            .toString();
        }
        if (
          item.dtretorno == "" ||
          moment(item.dtretorno).toString() == "Invalid date"
        ) {
          item.dtretorno = " - ";
        } else {
          item.dtretorno = moment(item.dtretorno)
            .format("DD/MM/Y HH:mm")
            .toString();
        }

        if (
          item.dtpedido == "" ||
          moment(item.dtpedido).format("DD/MM/Y HH:mm").toString() ==
            "Invalid date"
        ) {
          item.dtpedido = " - ";
        } else {
          item.dtpedido = moment(item.dtpedido)
            .format("DD/MM/Y HH:mm")
            .toString();
        }

        if (
          item.dtfaturamento == "" ||
          moment(item.dtfaturamento).format("DD/MM/Y HH:mm").toString() ==
            "Invalid date"
        ) {
          item.dtfaturamento = " - ";
        } else {
          item.dtfaturamento = moment(item.dtfaturamento)
            .format("DD/MM/Y HH:mm")
            .toString();
        }
        linhasNovas.push(
          createData(
            item.id,
            item.codfilial,
            item.numpedol,
            item.numpederp,
            item.dtimportacao,
            item.dtretorno,
            item.dtpedido,
            item.dtfaturamento,
            item.obs,
            item.layout,
            item.uuid
          )
        );
      });
      setRows(linhasNovas);
    }
  };

  React.useEffect(() => {
    // const listarLayouts = async () => {
    //     var resposta = await apiFarmix('get', '/v1/Order/cards', null);
    //     if (resposta.data) {
    //         resposta = resposta.data.results;
    //         localStorage.setItem('cardsHome', JSON.stringify(resposta));
    //         setListaCards(
    //             <>
    //                 {
    //                     [].map.call(resposta, function (informacao) {
    //                         return (
    //                             <Grid item xs={12} lg={3} md={6} sm={12} key={informacao.layoutid} className="p-2">
    //                                 <Paper elevation={1} component={Link} to={"/dashboard/por-cliente/" + informacao.layoutid + '/' + informacao.ol} style={{ textDecoration: 'none' }}>
    //                                     <Card1 style={{ backgroundColor: informacao.color }}>
    //                                         <Grid container alignItems={'center'} alignContent={'center'}>
    //                                             <Grid item xs={3} lg={3} md={2} sm={2} className="text-center">
    //                                                 <FaMedkit style={{ fontSize: 64 }} />
    //                                             </Grid>
    //                                             <Grid item xs={9} lg={9} md={9} sm={9}>
    //                                                 <CardBody>
    //                                                     <Titulo>
    //                                                         {informacao.ol}
    //                                                     </Titulo>
    //                                                     <div className="text-left">
    //                                                         {informacao.qtyanalysis} Em Analise
    //                                                     </div>
    //                                                     <div className="text-left">
    //                                                         {informacao.qtyinvoiced} Não Faturados
    //                                                     </div>
    //                                                     <div className="text-left">
    //                                                         {informacao.qtyrefused} Pendentes
    //                                                     </div>
    //                                                 </CardBody>
    //                                             </Grid>
    //                                         </Grid>
    //                                     </Card1>
    //                                 </Paper>
    //                             </Grid>
    //                         );
    //                     })
    //                 }
    //             </>
    //         );
    //     }
    // }
    // listarLayouts();
    listarPedidosPendentes();
  }, []);

  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState("small");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const caixaDeTexto = React.useRef(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //     const selectedIndex = selected.indexOf(name);
  //     let newSelected = [];

  //     if (selectedIndex === -1) {
  //         newSelected = newSelected.concat(selected, name);
  //     } else if (selectedIndex === 0) {
  //         newSelected = newSelected.concat(selected.slice(1));
  //     } else if (selectedIndex === selected.length - 1) {
  //         newSelected = newSelected.concat(selected.slice(0, -1));
  //     } else if (selectedIndex > 0) {
  //         newSelected = newSelected.concat(
  //             selected.slice(0, selectedIndex),
  //             selected.slice(selectedIndex + 1),
  //         );
  //     }

  //     setSelected(newSelected);

  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //     setDense(event.target.checked);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filtrarTabela = async (texto) => {
    texto = retornandoSomenteInteiro(texto);
    let resposta = await apiFarmix("get", `/v1/Order/filter/${texto}`, null);
    if (resposta.data) {
      let linhasNovas = [];
      resposta.data.results.forEach((item) => {
        if (
          item.dtimportacao == "" ||
          moment(item.dtimportacao).format("DD/MM/Y HH:mm").toString() ==
            "Invalid date"
        ) {
          item.dtimportacao = " - ";
        } else {
          item.dtimportacao = moment(item.dtimportacao)
            .format("DD/MM/Y HH:mm")
            .toString();
        }

        if (
          item.dtretorno == "" ||
          moment(item.dtretorno).format("DD/MM/Y HH:mm").toString() ==
            "Invalid date"
        ) {
          item.dtretorno = " - ";
        } else {
          item.dtretorno = moment(item.dtretorno)
            .format("DD/MM/Y HH:mm")
            .toString();
        }

        if (
          item.dtpedido == "" ||
          moment(item.dtpedido).format("DD/MM/Y HH:mm").toString() ==
            "Invalid date"
        ) {
          item.dtpedido = " - ";
        } else {
          item.dtpedido = moment(item.dtpedido)
            .format("DD/MM/Y HH:mm")
            .toString();
        }

        if (
          item.dtfaturamento == "" ||
          moment(item.dtfaturamento).format("DD/MM/Y HH:mm").toString() ==
            "Invalid date"
        ) {
          item.dtfaturamento = " - ";
        } else {
          item.dtfaturamento = moment(item.dtfaturamento)
            .format("DD/MM/Y HH:mm")
            .toString();
        }

        linhasNovas.push(
          createData(
            item.id,
            item.codfilial,
            item.numpedol,
            item.numpederp,
            item.dtimportacao,
            item.dtretorno,
            item.dtpedido,
            item.dtfaturamento,
            item.obs,
            item.layout,
            item.uuid
          )
        );
        // linhasNovas.push(
        //     createData(item.id, item.codfilial, item.numpedol, item.numpederp, moment(item.dtimportacao).format('DD/MM/Y HH:mm').toString(), moment(item.dtretorno).format('DD/MM/Y HH:mm').toString(), mascaraData(item.dtpedido), moment(item.dtfaturamento).format('DD/MM/Y HH:mm').toString(), item.obs)
        // );
      });
      setRows(linhasNovas);
    }
  };

  const [open, setOpen] = React.useState(false);
  const [msgModal, setMsgModal] = React.useState(false);
  const [modalBody, setModalBody] = React.useState("");
  const [modalBtnAcao, setModalBtnAcao] = React.useState(
    <div>
      <Button
        variant="contained"
        style={{ backgroundColor: "#ECF0F1" }}
        size="small"
        onClick={() => handleClose()}
      >
        Cancelar
      </Button>
    </div>
  );
  const handleClose = () => {
    setOpen(false);
  };

  // const exibirTypeOfCancel = async (layout, id) => {
  //     let typeofcancel = await apiFarmix('get', `/v1/Order/typeofcancel/${layout}`, null);
  //     if (typeofcancel != false) {
  //         typeofcancel = typeofcancel.data.results;
  //         setModalBody(
  //             <>
  //                 <Alert severity="warning" style={{ marginBottom: 10 }}>Após exluir o pedido, não será possível recuperar o pedido!</Alert>
  //                 {
  //                     [].map.call(typeofcancel, function (informacao) {
  //                         return (
  //                             <Box display="flex" key={informacao.id} style={{ padding: 5, marginBottom: 5, border: 'solid 2px #ecf0f1', borderRadius: 5 }}>
  //                                 <Box>
  //                                     <div onClick={() => exibirReasontoCancel(layout, informacao.id, id)}>
  //                                         <FaDotCircle style={{ fontSize: 18 }} />
  //                                     </div>
  //                                 </Box>
  //                                 <Box flexGrow={1}>
  //                                     <div style={{ paddingTop: 3, paddingLeft: 3 }}>
  //                                         {informacao.description}
  //                                     </div>
  //                                 </Box>
  //                             </Box>
  //                         );
  //                     })
  //                 }
  //             </>
  //         )
  //     }
  // }

  const exibirReasontoCancel = async (layout, typeofcancel, id) => {
    let reasontocancel = await apiFarmix(
      "get",
      `/v1/Order/reasontocancel/${layout}`,
      null
    );
    if (reasontocancel != false) {
      reasontocancel = reasontocancel.data.results;
      setModalBody(
        <>
          <Alert severity="warning" style={{ marginBottom: 10 }}>
            Após exluir o pedido, não será possível recuperar o pedido!
          </Alert>
          {[].map.call(reasontocancel, function (informacao) {
            return (
              <Box
                display="flex"
                alignItems="center"
                key={informacao.id}
                style={{
                  padding: 5,
                  marginBottom: 5,
                  border: "solid 2px #ecf0f1",
                  borderRadius: 5,
                  cursor: "pointer",
                }}
                onClick={() => excluirPedido(id, typeofcancel, informacao.id)}
              >
                <Box p={1}>
                  <div
                    style={{
                      paddingTop: 5,
                      cursor: "pointer",
                    }}
                  >
                    <FaDotCircle
                      className="text-secondary"
                      style={{ fontSize: 18 }}
                    />
                  </div>
                </Box>
                <Box p={1} flexGrow={1}>
                  <div
                    style={{
                      paddingTop: 0,
                      paddingLeft: 5,
                    }}
                  >
                    {informacao.description}
                  </div>
                </Box>
              </Box>
            );
          })}
        </>
      );
    }
  };

  const handleOpen = async (layout, id) => {
    setMsgModal("");
    await exibirReasontoCancel(layout, 2, id);
    setOpen(true);
  };

  const excluirPedido = async (orderid, typeofcancel, reasonid) => {
    // try {

    setModalBtnAcao("");
    let resposta = await apiFarmix("put", `/v1/Order/cancel`, {
      orderid: orderid,
      typeofcancel: typeofcancel,
      reasontocancelid: reasonid,
      userid: parseInt(
        localStorage.getItem("fb70004dd183ab753b99cd98543eb6c5_userid")
      ),
    });
    setModalBody(
      <>
        <div
          style={{ textAlign: "center", padding: "10px", marginTop: "10px" }}
        >
          <CircularProgress color="secondary" />
          <h3
            style={{ textAlign: "center", padding: "10px", marginTop: "10px" }}
          >
            Cancelando pedido...
          </h3>
        </div>
      </>
    );
    if (
      resposta != undefined &&
      (resposta?.status == 400 ||
        resposta?.response?.status == 400 ||
        resposta?.status == 401 ||
        resposta?.response?.status == 401 ||
        resposta?.status == 403 ||
        resposta?.response?.status == 403)
    ) {
      let status =
        resposta?.status === undefined
          ? resposta?.response?.status
          : resposta?.response?.status;
      let mensagemErro = "";
      if (
        resposta?.response?.data?.suggestion === undefined &&
        resposta?.response?.data?.suggestion === "" &&
        status !== 403
      ) {
        // console.log('msg', resposta?.response?.data?.suggestion)
        mensagemErro = "Ops! Ocorreu um erro";
      } else if (status === 403) {
        mensagemErro = "Ops! Você não tem permissão";
      } else if (
        resposta?.response?.data?.suggestion !== undefined &&
        resposta.response.data.suggestion !== "" &&
        resposta?.response?.data?.suggestion?.length > 0
      ) {
        mensagemErro = `
                    ${
                      resposta.response.data.suggestion ??
                      resposta.response.data.suggestion
                    } 
                    ${
                      resposta.response.data.description ??
                      resposta.response.data.description
                    } 
                `;
      } else {
        // console.log('msgresposta', resposta)
        // console.log('msg', resposta?.response?.data?.suggestion)
        mensagemErro = "Ops! Ocorreu um erro";
      }
      setModalBody("");
      setMsgModal(
        <Alert severity="warning" style={{ marginBottom: 25 }}>
          {mensagemErro}
        </Alert>
      );
      setModalBtnAcao(
        <div>
          <Button
            variant="contained"
            style={{ backgroundColor: "#ECF0F1" }}
            size="small"
            onClick={() => handleClose()}
          >
            Fechar
          </Button>
        </div>
      );
    } else {
      await listarPedidosPendentes();
      handleClose();
    }
    // } catch (error) {
    //     setMsgModal(
    //         <Alert severity="warning" style={{ marginBottom: 25 }}>
    //             Ops! Ocorreu um erro, tente novamente mais tarde
    //         </Alert>
    //     );
    // }
  };

  const [openModalReenvio, setOpenModalReenvio] = React.useState(false);
  const [uuidSelecionado, setUuidSelecionado] = React.useState("");

  return (
    <Template titulo={"Inicio"}>
      {/* <Grid container spacing={3} alignItems={'center'} alignContent={'center'}>
                {listaCards}
            </Grid> */}
      <Grid container spacing={3} alignItems={"center"} alignContent={"center"}>
        <Grid item xs={12} lg={12} md={12} sm={12} className="p-2">
          <br />
          <Paper elevation={1}>
            <div style={{ width: "100%" }}>
              <TituloFiltroAvancado>Filtro Avançado</TituloFiltroAvancado>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  <FiltroAvancado>
                    <TextField
                      id="outlined-basic"
                      label="Nº pedido, CPF ou CNPJ"
                      variant="outlined"
                      style={{ width: "100%" }}
                      size="small"
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          filtrarTabela(event.target.value);
                          setTextoPesquisa(event.target.value);
                        }
                      }}
                      defaultValue={textoPesquisa}
                      placeholder="Pesquise aqui..."
                    />
                  </FiltroAvancado>
                </Box>
                <Box p={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ padding: 13 }}
                    onClick={() => {
                      setTextoPesquisa("");
                      listarPedidosPendentes();
                    }}
                  >
                    <FaEraser />
                  </Button>
                </Box>
              </Box>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12} className="p-2">
          <Paper elevation={1} style={{ padding: 15 }}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            {/* <EnhancedTableToolbar /> */}
            <TableContainer>
              <Table
                classID={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  // numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          // selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell> */}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="center"
                          >
                            {row.id}
                          </TableCell>
                          <TableCell align="right">{row.codfilial}</TableCell>
                          <TableCell align="right">{row.numpedol}</TableCell>
                          <TableCell align="right">{row.numpederp}</TableCell>
                          <TableCell align="center">
                            {row.dtimportacao}
                          </TableCell>
                          <TableCell align="center">{row.dtretorno}</TableCell>
                          <TableCell align="center">{row.dtpedido}</TableCell>
                          <TableCell align="center">
                            {row.dtfaturamento}
                          </TableCell>
                          <TableCell align="left">
                            {row.obs == null || row.obs == " "
                              ? " - "
                              : Parser(row.obs)}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setUuidSelecionado(row.uuid);
                                setOpenModalReenvio(true);
                              }}
                            >
                              <FaFileUpload style={{ color: "#fff" }} />
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Link to={"/dashboard/pedidos/detalhe/" + row.id}>
                              <Button variant="contained" color="primary">
                                <FaExternalLinkAlt />
                              </Button>
                            </Link>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "#e57373",
                                color: "#fff",
                              }}
                              onClick={() =>
                                handleOpen(row.layout, row.id, row.uuid)
                              }
                            >
                              <FaTrash />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalContent>
          <Paper style={{ borderRadius: "10px" }}>
            <ModalTitle>Excluir Pedido</ModalTitle>
            <ModalBody>
              <div>{msgModal}</div>
              {modalBody}
              <ModalFooter>{modalBtnAcao}</ModalFooter>
            </ModalBody>
          </Paper>
        </ModalContent>
      </Modal>
      <ModalReenvio
        openModalReenvio={openModalReenvio}
        setOpenModalReenvio={setOpenModalReenvio}
        uuid={uuidSelecionado}
      />
    </Template>
  );
}

const FiltroAvancado = styled.div`
  padding: 10px 10px;
  margin: 0px;
  /* margin-bottom: 1rem; */
  text-align: left;
  font-size: 17px;
  font-weight: 500;
  /* background-color: #4791db; */
`;

const TituloFiltroAvancado = styled.h3`
  padding-left: 13px;
  padding-right: 0px;
  padding-top: 20px;
  padding-bottom: 0px;
  margin: 0;
  margin-bottom: 0.5rem;
  text-align: left;
  font-size: 17px;
  font-weight: 500;
  /* background-color: #4791db; */
`;
const Card1 = styled.div`
  padding: 0px 0px;
  margin: 0px;
  /* margin-bottom: 1rem; */
  text-align: left;
  font-size: 17px;
  font-weight: 500;
  /* background-color: #4791db; */
  border-radius: 0.25rem;
  color: #34495e;
  text-decoration: none;
`;

const CardBody = styled.div`
  padding: 15px 20px;
  margin: 0px;
  text-align: left;
  font-size: 16px;
  color: #34495e;
  text-decoration: none;
`;

const Titulo = styled.h1`
  padding: 0px 0px;
  margin: 0px;
  text-align: left;
  font-size: 26px;
  font-weight: 400;
  text-decoration: none;
`;

const ModalContent = styled.div`
  max-width: 512px;
  margin: auto;
  margin-top: 10%;
  border: solid 1.5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const ModalBody = styled.div`
  background-color: #fff;
  padding: 20px 20px;
`;

const ModalTitle = styled.div`
  background-color: #4791db;
  color: #fff;
  text-align: center;
  /* border-radius: 5px; */
  padding: 10px 10px;
  font-size: 22px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  margin-top: 10px;
  & > div {
    padding: 2px;
  }
`;
