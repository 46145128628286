import React from 'react';
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { Template } from '../../components/template/index';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { BsCardChecklist } from 'react-icons/bs';
import Alert from '@material-ui/lab/Alert';

export default function HomePorCliente(props) {
    document.title = 'Cliente - Dashboard';
    const [nomeOL, setNomeOL] = React.useState('');
    const [mensagem, setMensagem] = React.useState(
        <div className="m-auto">
            <div className="text-center p-3 mt-5">
                <CircularProgress color="primary" />
                <p className="p-2 text-center fontSize17">
                    Carregando...
                </p>
            </div>
        </div>
    );
    const [cardAguardandoFaturamento, setCardAguardandoFaturamento] = React.useState({
        'valor': 0,
        'link': '#'
    });
    const [cardPedidosPendentes, setCardPedidosPendentes] = React.useState({
        'valor': 0,
        'link': '#'
    });
    const [cardAguardandoAnalise, setCardAguardandoAnalise] = React.useState({
        'valor': 0,
        'link': '#'
    });
    const [cardPedidosCancelados, setPedidosCancelados] = React.useState({
        'valor': 0,
        'link': '#'
    });

    React.useEffect(() => {
        const selecionaLayout = async () => {
            let id = props.match.params.id;
            let nome = props.match.params.nome;
            setNomeOL(nome);
            await listarDadosNosCards(id);
        }
        selecionaLayout();
    }, [])

    // "status": 0 => [PEDIDOS PENDENTES]
    // "status": 1 => [AGUARDANDO ANÁLISE]
    // "status": 2 => [AGUARDANDO FATURAMENTO]
    // "status": 3 => [PEDIDOS CANCELADOS]

    const listarDadosNosCards = async (layoutID) => {
        // var resposta = await apiFarmix('get', `/v1/Order/pendency?layoutid=${layoutID}`, null);
        let cardsHome = [];
        // try {
        if (localStorage.getItem('cardsHome')) {
            setMensagem('')
            cardsHome = JSON.parse(localStorage.getItem('cardsHome'));
            // console.log(cardsHome)
            var card = [];
            for (let index = 0; index < cardsHome.length; index++) {
                const item = cardsHome[index];
                if (item.layoutid == layoutID) {
                    card = item;
                    // console.log(item)
                    break;
                }
            }
            setMensagem(<Alert severity="warning" style={{ fontSize: 17 }}>
                Ops! Ocorreu um erro ao buscar dados.
            </Alert>);
            // console.log(card)
            setCardAguardandoFaturamento({
                'valor': card.qtyinvoiced,
                'link': '#'
            });
            setCardPedidosPendentes({
                'valor': card.qtyrefused,
                'link': '#'
            });
            setCardAguardandoAnalise({
                'valor': card.qtyanalysis,
                'link': '#'
            });
            setPedidosCancelados({
                'valor': card.qtycanceled,
                'link': '#'
            });
            setMensagem('')
        } else {
            setMensagem(<Alert severity="warning" style={{ fontSize: 17 }}>
                Ops! Ocorreu um erro ao buscar dados.
            </Alert>);
            setCardAguardandoFaturamento({
                'valor': 0,
                'link': '#'
            });
            setCardPedidosPendentes({
                'valor': 0,
                'link': '#'
            });
            setCardAguardandoAnalise({
                'valor': 0,
                'link': '#'
            });
            setPedidosCancelados({
                'valor': 0,
                'link': '#'
            });
        }
        // } catch (error) {
        //     if (cardsHome === undefined) {
        //         setMensagem(<Alert severity="warning" style={{ fontSize: 17 }}>
        //             Ops! Ocorreu um erro ao buscar dados.
        //         </Alert>);
        //         // console.log('error')
        //         setCardAguardandoFaturamento({
        //             'valor': 0,
        //             'link': '#'
        //         });
        //         setCardPedidosPendentes({
        //             'valor': 0,
        //             'link': '#'
        //         });
        //         setCardAguardandoAnalise({
        //             'valor': 0,
        //             'link': '#'
        //         });
        //         setPedidosCancelados({
        //             'valor': 0,
        //             'link': '#'
        //         });
        //     }
        // }
        //// console.log(JSON.stringify(resposta.data.results.length))
        // setListaDeLayouts(resposta)
    }

    return (
        <Template titulo={'Inicio'} nomeOL={nomeOL}>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={12} md={12} sm={12}>
                    {mensagem}
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={3} md={6} sm={12}>
                    <Paper>
                        <Card1>
                            <CardBody>
                                <Box display="flex" m={0} alignItems="center">
                                    <Box p={1} flexGrow={1}>
                                        <div style={{ fontSize: 15 }}>Aguardando Faturamento</div>
                                        <Strong>{cardAguardandoFaturamento.valor}</Strong>
                                    </Box>
                                    <Box p={1}>
                                        <BsCardChecklist style={{ fontSize: 48 }} />
                                    </Box>
                                </Box>
                            </CardBody>
                            <Button component={Link} to={"/dashboard/pedidos/nao-faturados/" + props.match.params.id + '/' + nomeOL} startIcon={<FaExternalLinkAlt style={{ color: 'white' }} />} variant="contained" color="primary" size={'small'} fullWidth style={{ color: '#4791db' }}>
                                <TextoBranco>Ver Pedidos</TextoBranco>
                            </Button>
                        </Card1>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={12}>
                    <Paper>
                        <Card2>
                            <CardBody>
                                <Box display="flex" m={0} alignItems="center">
                                    <Box p={1} flexGrow={1}>
                                        <div style={{ fontSize: 15 }}>Pedidos Pendentes</div>
                                        <Strong>{cardPedidosPendentes.valor}</Strong>
                                    </Box>
                                    <Box p={1}>
                                        <BsCardChecklist style={{ fontSize: 48 }} />
                                    </Box>
                                </Box>
                            </CardBody>
                            <Button component={Link} to={"/dashboard/pedidos/pendentes/" + props.match.params.id + '/' + nomeOL} startIcon={<FaExternalLinkAlt style={{ color: 'dark' }} />} variant="contained" color="primary" size={'small'} fullWidth style={{ color: '#fff', backgroundColor: '#ffb74d' }}>
                                Ver pedidos
                            </Button>
                        </Card2>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={12}>
                    <Paper>
                        <Card3>
                            <CardBody>
                                <Box display="flex" m={0} alignItems="center">
                                    <Box p={1} flexGrow={1}>
                                        <div style={{ fontSize: 15 }}>Aguardando Análise</div>
                                        <Strong>{cardAguardandoAnalise.valor}</Strong>
                                    </Box>
                                    <Box p={1}>
                                        <BsCardChecklist style={{ fontSize: 48 }} />
                                    </Box>
                                </Box>
                            </CardBody>
                            <Button component={Link} to={"/dashboard/pedidos/em-analise/" + props.match.params.id + '/' + nomeOL} startIcon={<FaExternalLinkAlt style={{ color: 'dark' }} />} variant="contained" color="primary" size={'small'} fullWidth style={{ color: '#fff', backgroundColor: '#81c784' }}>
                                Ver pedidos
                            </Button>
                        </Card3>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={3} md={6} sm={12}>
                    <Paper>
                        <Card4>
                            <CardBody>
                                <Box display="flex" m={0} alignItems="center">
                                    <Box p={1} flexGrow={1}>
                                        <div style={{ fontSize: 15 }}>Pedidos Cancelados</div>
                                        {/* <Strong style={{color: '#e57373'}}>{cardPedidosCancelados.valor}</Strong> */}
                                        <div style={{ height: '40px' }}></div>
                                    </Box>
                                    <Box p={1}>
                                        <BsCardChecklist style={{ fontSize: 48 }} />
                                    </Box>
                                </Box>
                            </CardBody>
                            <Button component={Link} to={"/dashboard/pedidos/cancelados/" + props.match.params.id + '/' + nomeOL} startIcon={<FaExternalLinkAlt style={{ color: 'dark' }} />} variant="contained" color="primary" size={'small'} fullWidth style={{ color: '#fff', backgroundColor: '#e57373' }}>
                                Ver pedidos
                            </Button>
                        </Card4>
                    </Paper>
                </Grid>
            </Grid>
        </Template>
    );
}

const Strong = styled.div`
    font-weight: 525;
    padding-top: 10px;
    font-size: 22px;
`;

const TextoBranco = styled.div`
    color: #fff;
`;

const Card1 = styled.div`
    padding: 0px 0px;
    margin: 0px;
    /* margin-bottom: 1rem; */
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    background-color: #4791db;
    border-radius: .25rem;
    color: #fff;
    `;

const CardBody = styled.div`
    padding: 15px 20px;
    margin: 0px;
    font-weight: 500;
    text-align: left;
    font-size: 17px;
    color: #fff;
    `;

const Card2 = styled.div`
    padding: 0px 0px;
    margin: 0px;
    /* margin-bottom: 1rem; */
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    background-color: #ffb74d;
    border-radius: .25rem;
    color: #fff;
    `;

const Card3 = styled.div`
    padding: 0px 0px;
    margin: 0px;
    /* margin-bottom: 1rem; */
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    background-color: #81c784;
    border-radius: .25rem;
    color: #fff;
    `;


const Card4 = styled.div`
padding: 0px 0px;
margin: 0px;
/* margin-bottom: 1rem; */
text-align: left;
font-size: 17px;
font-weight: 500;
background-color: #e57373;
border-radius: .25rem;
color: #fff;
`;

const DropdownContent = styled.div`
    position: absolute;
    background-color: #fff;
    padding: 10px 20px;
    margin-top: -0px;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    `;