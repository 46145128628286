import React from 'react';
import { isAuthenticated } from './auth';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Login from './pages/login';
import Sair from './pages/login/index';
import EsqueciMinhaSenha from './pages/esqueci-minha-senha';
import Home from './pages/dashboard/home';
import HomePorCliente from './pages/dashboard/home-cliente';
import Usuarios from './pages/dashboard/usuarios';
import CadastrarUsuario from './pages/dashboard/usuarios/cadastrar';
import Pedidos from './pages/dashboard/pedidos';
import PedidosCancelados from './pages/dashboard/pedidos/cancelados';
import DetalhePedido from './pages/dashboard/pedidoDetalhe';
import NaoPossuiPermissao from './pages/dashboard/nao-possui-permissao';
// import api from './services/api';
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            )
        )} />
)

export default function Routes({ props }) {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/login" exact component={Login} />
                <Route path="/sair" exact component={Sair} />
                <PrivateRoute path="/esqueci-minha-senha" exact component={EsqueciMinhaSenha} />
                <PrivateRoute path="/dashboard/home" exact component={Home} />
                <PrivateRoute path="/dashboard/por-cliente/:id/:nome" exact component={HomePorCliente} />
                <PrivateRoute path="/dashboard/usuarios" exact component={Usuarios} />
                <PrivateRoute path="/dashboard/usuarios/cadastrar" exact component={CadastrarUsuario} />
                <PrivateRoute path="/dashboard/pedidos/detalhe/:id" exact component={DetalhePedido} />
                <PrivateRoute path="/dashboard/pedidos/cancelados/:cliente/:nome" exact component={PedidosCancelados} />
                <PrivateRoute path="/dashboard/pedidos/:status/:cliente/:nome" exact component={Pedidos} />
                <PrivateRoute path="/dashboard/nao-possui-permissao" exact component={NaoPossuiPermissao} />
            </Switch>
        </BrowserRouter>
    );
}