import React, { useState } from "react";
import "./style.css";
import styled from "styled-components";
import api from "../../../services/api";
import { Template } from "../../components/template/index";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { Controller, useForm } from "react-hook-form";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
// Icones Novos
import { IoPersonAddOutline, IoSaveOutline } from "react-icons/io5";

export default function CadastrarUsuario() {
  const refName = React.useRef(null);
  const refSurname = React.useRef(null);
  const refEmail = React.useRef(null);
  const refPassword = React.useRef(null);
  const [exibeForm, setExibeForm] = useState(true);
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [mensagem, setMensagem] = useState(<></>);

  const onSubmit = async (data) => {
    setExibeForm(false);
    setMensagem(
      <Paper>
        <div className="text-center p-3 mt-5">
          <CircularProgress color="primary" />
          <p className="p-2 text-center fontSize17">Registrando usuário...</p>
        </div>
      </Paper>
    );
    data["profileid"] = parseInt(data["profileid"]);
    data["subsidiaryid"] = parseInt(data["subsidiaryid"]);
    let resposta = await api({
      method: "post",
      url: "/v1/User",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          "fb70004dd183ab753b99cd98543eb6c5_token"
        )}`,
      },
      data: data,
    })
      .then(function (response) {
        // console.log('resposta form', response.data)
        return response.data;
      })
      .catch(function (error) {
        // console.log(error)
        return error;
      });
    // console.log('error', resposta);
    if (
      resposta &&
      (resposta?.status === 400 ||
        resposta?.response?.status === 400 ||
        resposta?.status === 401 ||
        resposta?.response?.status === 401 ||
        resposta?.status === 403 ||
        resposta?.response?.status === 403)
    ) {
      let status =
        resposta?.status === undefined
          ? resposta?.response?.status
          : resposta?.response?.status;
      let mensagemErro = "";
      if (
        resposta?.response?.data?.suggestion === undefined &&
        resposta?.response?.data?.suggestion === "" &&
        status !== 403
      ) {
        mensagemErro = "Ops! Ocorreu um erro";
      } else if (status === 403) {
        mensagemErro = "Ops! Você não tem permissão";
      } else if (
        resposta?.response?.data?.suggestion !== undefined &&
        resposta.response.data.suggestion !== "" &&
        resposta?.response?.data?.suggestion?.length > 0
      ) {
        mensagemErro = `
                    ${
                      resposta.response.data.suggestion ??
                      resposta.response.data.suggestion
                    } 
                    ${
                      resposta.response.data.description ??
                      resposta.response.data.description
                    } 
                `;
      } else {
        mensagemErro = "Ops! Ocorreu um erro";
      }
      setMensagem(
        <Paper elevation={1}>
          <Alert severity="warning" style={{ marginBottom: 25 }}>
            {mensagemErro}
          </Alert>
        </Paper>
      );
      setExibeForm(true);
    } else {
      setMensagem(
        <Paper elevation={1}>
          <Alert severity="success">
            Parabéns! Cadastro realizado com sucesso!
          </Alert>
        </Paper>
      );
    }
  };

  const inputPropsName = {
    ref: refName,
  };
  const inputPropsSurname = {
    ref: refSurname,
  };
  const inputPropsEmail = {
    ref: refEmail,
  };
  const inputPropsPassword = {
    ref: refPassword,
  };
  return (
    <Template titulo={"Usuários"}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Paper elevation={1}>
            <TituloCard>
              <Box
                display="flex"
                p={0}
                m={0}
                alignItems="center"
                className="text-primary pt-2"
              >
                <Box p={0}>
                  <IoPersonAddOutline className="iconLarge" />
                </Box>
                <Box p={0} flexGrow={1}>
                  <Titulo>Cadastrar Usuário</Titulo>
                </Box>
              </Box>
            </TituloCard>
          </Paper>
          <br />
          {mensagem}
          {exibeForm && (
            <Paper elevation={1} className="p-3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="pt-3">
                  <TextField
                    size="small"
                    required
                    label="Nome Completo"
                    variant="outlined"
                    inputProps={inputPropsName}
                    fullWidth
                    focused={true}
                    {...register("name", { required: true })}
                  />
                </div>
                <div className="pt-3">
                  <TextField
                    size="small"
                    required
                    label="Nome de usuário"
                    variant="outlined"
                    inputProps={inputPropsSurname}
                    focused={true}
                    {...register("surname", { required: true })}
                    fullWidth
                  />
                </div>
                <div className="pt-3">
                  <InputLabel shrink>Perfil</InputLabel>
                  <Controller
                    control={control}
                    name="profileid"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        label="Perfil"
                        variant="outlined"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>Selecione</em>
                        </MenuItem>
                        {/* <MenuItem value={'1'}>Sys Admin</MenuItem> */}
                        <MenuItem value={"2"}>Admin</MenuItem>
                        <MenuItem value={"3"}>Public</MenuItem>
                        <MenuItem value={"4"}>Integrador</MenuItem>
                      </Select>
                    )}
                  />
                </div>
                <div className="pt-3">
                  <InputLabel shrink>Filial</InputLabel>
                  <Controller
                    control={control}
                    name="subsidiaryid"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        label="Filial"
                        variant="outlined"
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>Selecione</em>
                        </MenuItem>
                        <MenuItem value={"1"}>Filial 1</MenuItem>
                        <MenuItem value={"2"}>Filial 2</MenuItem>
                      </Select>
                    )}
                  />
                </div>
                <div className="pt-3">
                  <TextField
                    size="small"
                    required
                    label="E-mail"
                    variant="outlined"
                    inputProps={inputPropsEmail}
                    focused={true}
                    {...register("email", { required: true })}
                    fullWidth
                  />
                </div>
                <div className="pt-3">
                  <TextField
                    size="small"
                    required
                    label="Senha"
                    type="password"
                    autoComplete="current-password"
                    variant="outlined"
                    inputProps={inputPropsPassword}
                    focused={true}
                    {...register("password", { required: true })}
                    fullWidth
                  />
                </div>
                <div className="pt-2">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<IoSaveOutline />}
                    type="submit"
                  >
                    Salvar
                  </Button>
                </div>
              </form>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Template>
  );
}

const TituloCard = styled.div`
  padding: 5px 20px;
  margin: 0px;
  /* margin-bottom: 1rem; */
  text-align: left;
  /* background-color: #f8f8f9; */
  font-size: 27px;
  font-weight: 500;
  border-bottom: #ccc solid 1px;
`;

const Titulo = styled.h1`
  padding: 0px 10px;
  margin: 0px;
  text-align: left;
  font-size: 22px;
`;

const Paragrafo = styled.h1`
  padding: 2.5px 5px;
  margin: 0px;
  text-align: left;
  font-size: 17px;
  font-weight: 500px;
`;

const NomeUsuario = styled.h5`
  font-size: 17px;
  padding: 0px;
  margin: 0px;
  font-weight: 500;
`;

const LinhaDivisao = styled.div`
  padding: 0px;
  margin: 0px;
  height: 1px;
`;
