import Routes from './router';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';

function App() {
  const theme = createMuiTheme({
    palette: {
      background: {
        primary: {
          main: '#4791db'
        },
        secondary: {
          main: '#ffb74d'
        },
        error: {
          main: '#e57373'
        },
        warning: {
          main: '#ffb74d'
        },
        info: {
          main: '#64b5f6'
        },
        success: {
          main: '#81c784'
        }
      },
      color: {
        primary: {
          main: '#4791db'
        },
        secondary: {
          main: '#424242'
        },
        error: {
          main: '#e57373'
        },
        warning: {
          main: '#ffb74d'
        },
        info: {
          main: '#64b5f6'
        },
        success: {
          main: '#81c784'
        }
      },
      primary: {
        main: '#4791db'
      },
      secondary: {
        main: '#ffb74d'
      },
      error: {
        main: '#e57373'
      },
      warning: {
        main: '#ffb74d'
      },
      info: {
        main: '#64b5f6'
      },
      success: {
        main: '#81c784'
      },
    }
  }, ptBR);
  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
