import React, { useState } from "react";
import styled from "styled-components";
import { Alert } from "@material-ui/lab";
import { apiFarmix } from "../../../services/api";
import { Template } from "../../components/template/index";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import {
  mascaraDocumento,
  mascaraData,
  retornandoSomenteInteiro,
} from "./../../../lib";
import { FaTrash, FaEdit } from "react-icons/fa";
import { GoTasklist } from "react-icons/go";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Parser from "html-react-parser";

function createData(
  codprod,
  ean,
  qtsol,
  qtatend,
  precool,
  descontool,
  vlcalcol,
  precoerp,
  descontoerp,
  vlcalcerp,
  obs
) {
  return {
    codprod,
    ean,
    qtsol,
    qtatend,
    precool,
    descontool,
    vlcalcol,
    precoerp,
    descontoerp,
    vlcalcerp,
    obs,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "codprod",
    numeric: true,
    disablePadding: false,
    label: "Cod Produto ",
  },
  { id: "ean", numeric: true, disablePadding: false, label: "EAN " },
  {
    id: "qtsol",
    numeric: true,
    disablePadding: false,
    label: "Qtd Solicitada",
  },
  {
    id: "qtatend",
    numeric: false,
    disablePadding: false,
    label: "Qtd Atendida ",
  },
  { id: "precool", numeric: true, disablePadding: false, label: "Preço OL " },
  {
    id: "descontool",
    numeric: true,
    disablePadding: false,
    label: "Desc. OL ",
  },
  {
    id: "vlcalcol",
    numeric: true,
    disablePadding: false,
    label: "Valor Calc OL ",
  },
  { id: "precoerp", numeric: true, disablePadding: false, label: "Preço ERP " },
  {
    id: "descontoerp",
    numeric: true,
    disablePadding: false,
    label: "Desconto ERP ",
  },
  {
    id: "vlcalcerp",
    numeric: false,
    disablePadding: false,
    label: "Valor Cal ERP ",
  },
  { id: "obs", numeric: false, disablePadding: true, label: "Obs. " },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            //align="center"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              align="center"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span classID={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
          <TableSortLabel>Editar</TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel>Cancelar</TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(
        classes.root
        //     , {
        //     [classes.highlight]: numSelected > 0,
        // }
      )}
    >
      {/* {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Lista de Pedidos
                </Typography>
            )} */}
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Lista de Pedidos
      </Typography>

      {/* {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )} */}
    </Toolbar>
  );
};

// EnhancedTableToolbar.propTypes = {
//     numSelected: PropTypes.number.isRequired,
// };

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function DetalhePedido(props) {
  const [pedidoSelecionado, setPedidoSelecionado] = React.useState([
    {
      id: 0,
      uuid: "",
      qtdLimite: 0,
    },
  ]);
  const qtdLimite = React.useRef(null);
  const [pedido, setPedido] = React.useState([]);
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState("small");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    // const exibirPedido = async () => {
    //   let id = props.match.params.id;
    //   setPedido(id);
    //   const resposta = await apiFarmix("get", `/v1/Item/${id}`, null);
    //   setRows(resposta.data.results);
    // };
    exibirPedido();
  }, []);

  const exibirPedido = async () => {
    let id = props.match.params.id;
    setPedido(id);
    const resposta = await apiFarmix("get", `/v1/Item/${id}`, null);
    setRows(resposta.data.results);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filtrarTabela = async (texto) => {
    texto = retornandoSomenteInteiro(texto);
    let resposta = await apiFarmix("get", `/v1/Order/filter/${texto}`, null);
    if (resposta.data) {
      let linhasNovas = [];
      resposta.data.results.forEach((item) => {
        linhasNovas.push(
          createData(
            item.id,
            mascaraDocumento(item.cnpj),
            item.codprod,
            mascaraDocumento(item.cnpjdist),
            item.qtsol
          )
        );
      });
      setRows(linhasNovas);
    }
  };
  const [open, setOpen] = React.useState(false);
  const [msgModal, setMsgModal] = React.useState(false);
  const [modalBody, setModalBody] = React.useState("");
  const [modalBtnAcao, setModalBtnAcao] = React.useState("");

  const handleOpen = (qtdLimitePedido, id, uuid, tipo) => {
    if (tipo == 1) {
      setModalBody(
        <TextField
          id="outlined-secondary"
          label="Quantidade"
          variant="outlined"
          color="primary"
          style={{ width: "100%" }}
          inputProps={{
            ref: qtdSolicitada,
            type: "number",
            max: qtdLimitePedido,
          }}
        />
      );
      setModalBtnAcao(
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => validarEdicaoItem(qtdLimitePedido, id, uuid)}
        >
          Salvar
        </Button>
      );
    } else {
      setModalBody("");
      setMsgModal(
        <Alert severity="warning" style={{ marginBottom: 25 }}>
          Tem certeza que deseja cancelar o item do pedido?
        </Alert>
      );
      setModalBtnAcao(
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => excluirItem(-1, id, uuid)}
        >
          Sim
        </Button>
      );
    }
    setOpen(true);
  };

  const handleClose = () => {
    setMsgModal("");
    setOpen(false);
  };

  const qtdSolicitada = React.useRef(null);

  const excluirItem = async (qtatend2, id, uuid) => {
    let dados = {
      orderid: id,
      uuid: uuid,
      qtatend2: parseInt(qtatend2),
      userid: parseInt(
        localStorage.getItem("fb70004dd183ab753b99cd98543eb6c5_userid")
      ),
    };
    setMsgModal("");
    // console.log('dados', dados)
    // console.log('dados', JSON.stringify(dados))
    let resposta = await apiFarmix("put", `/v1/order/cuteitem`, dados);
    console.log(resposta);
    if (
      resposta != undefined &&
      (resposta?.status == 400 ||
        resposta?.response?.status == 400 ||
        resposta?.status == 401 ||
        resposta?.response?.status == 401 ||
        resposta?.status == 403 ||
        resposta?.response?.status == 403)
    ) {
      let status =
        resposta?.status === undefined
          ? resposta?.response?.status
          : resposta?.response?.status;
      let mensagemErro = "";
      if (
        resposta?.response?.data?.suggestion === undefined ||
        resposta?.response?.data?.suggestion === "" ||
        status !== 403
      ) {
        mensagemErro = "Ops! Ocorreu um erro";
      } else if (status === 403) {
        mensagemErro = "Ops! Você não tem permissão";
      } else if (
        resposta?.response?.data?.suggestion !== undefined &&
        resposta.response.data.suggestion !== "" &&
        resposta?.response?.data?.suggestion?.length > 0
      ) {
        mensagemErro = `
                    ${
                      resposta.response.data.suggestion ??
                      resposta.response.data.suggestion
                    } 
                    ${
                      resposta.response.data.description ??
                      resposta.response.data.description
                    } 
                `;
      } else {
        mensagemErro = "Ops! Ocorreu um erro";
      }
      setModalBody("");
      setMsgModal(
        <Alert severity="warning" style={{ marginBottom: 25 }}>
          {mensagemErro}
        </Alert>
      );
    } else {
      exibirPedido();
      handleClose();
    }
  };
  const validarEdicaoItem = async (qtdLimite, id, uuid) => {
    if (
      qtdLimite >= qtdSolicitada.current.value.trim() &&
      qtdSolicitada.current.value.length != 0 &&
      qtdSolicitada.current.value.trim() != "" &&
      qtdSolicitada.current.value.trim() != 0
    ) {
      let dados = {
        orderid: parseInt(id),
        uuid: uuid,
        qtatend2: parseInt(qtdSolicitada.current.value),
        userid: parseInt(
          localStorage.getItem("fb70004dd183ab753b99cd98543eb6c5_userid")
        ),
      };
      setMsgModal("");
      // console.log('dados', dados)
      // console.log('dados', JSON.stringify(dados))
      let resposta = await apiFarmix("put", `/v1/order/cuteitem`, dados);
      if (
        resposta != undefined &&
        (resposta?.status == 400 ||
          resposta?.response?.status == 400 ||
          resposta?.status == 401 ||
          resposta?.response?.status == 401 ||
          resposta?.status == 403 ||
          resposta?.response?.status == 403)
      ) {
        let status =
          resposta?.status === undefined
            ? resposta?.response?.status
            : resposta?.response?.status;
        let mensagemErro = "";
        if (
          resposta?.response?.data?.suggestion === undefined &&
          resposta?.response?.data?.suggestion === "" &&
          status !== 403
        ) {
          mensagemErro = "Ops! Ocorreu um erro";
        } else if (
          resposta?.response?.data?.suggestion === undefined &&
          resposta?.response?.data?.suggestion === "" &&
          status === 403
        ) {
          mensagemErro = "Ops! Você não tem permissão";
        } else if (
          resposta?.response?.data?.suggestion !== undefined &&
          resposta.response.data.suggestion !== "" &&
          resposta?.response?.data?.suggestion?.length > 0
        ) {
          mensagemErro = `
                        ${
                          resposta.response.data.suggestion ??
                          resposta.response.data.suggestion
                        } 
                        ${
                          resposta.response.data.description ??
                          resposta.response.data.description
                        } 
                    `;
        } else {
          mensagemErro = "Ops! Ocorreu um erro";
        }
        setMsgModal(
          <Alert severity="warning" style={{ marginBottom: 25 }}>
            {mensagemErro}
          </Alert>
        );
      } else {
        exibirPedido();
        handleClose();
      }
    } else {
      qtdSolicitada.current.value = qtdLimite - 1;
      setMsgModal(
        <Alert severity="warning" style={{ marginBottom: 25 }}>
          O valor máximo permitido é de <strong>{qtdLimite} unidades.</strong>
        </Alert>
      );
    }
  };

  return (
    <Template titulo={"Detalhe do Pedido"}>
      <Paper elevation={1} style={{ padding: 15 }} p={1}>
        <Typography variant="h5" component="h2">
          Nº do Pedido: {pedido}
        </Typography>
      </Paper>
      <Paper elevation={1} style={{ padding: 15, marginTop: 20 }}>
        <Box display="flex" p={0} alignItems="center">
          <Box p={1}>
            <GoTasklist style={{ fontSize: 22 }} />
          </Box>
          <Box p={1} flexGrow={1}>
            <Typography variant="h5" component="h2">
              Itens do Pedido
            </Typography>
          </Box>
        </Box>
        <TableContainer>
          <Table
            classID={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      // selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell> */}
                      <TableCell align="center">
                        {row.codprod == null ? "-" : row.codprod}
                      </TableCell>
                      <TableCell align="right">
                        {row.ean == null ? "-" : row.ean}
                      </TableCell>
                      <TableCell align="right">
                        {row.qtsol == null ? "-" : row.qtsol}
                      </TableCell>
                      <TableCell align="right">
                        {row.qtatend2 == null ||
                        row.qtatend2 == 0 ||
                        row.qtatend2 == -1
                          ? row.qtatend == null
                            ? "-"
                            : row.qtatend
                          : row.qtatend2}
                      </TableCell>
                      <TableCell align="right">
                        {row.precool == null || row.precool == 0
                          ? "-"
                          : new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(row.precool)}
                      </TableCell>
                      <TableCell align="right">
                        {row.descontool == null || row.descontool == 0
                          ? "-"
                          : new Intl.NumberFormat("pt-BR", {
                              style: "decimal",
                              currency: "BRL",
                              minimumFractionDigits: 2,
                            }).format(row.descontool)}
                      </TableCell>
                      <TableCell align="right">
                        {row.vlcalcol == null || row.vlcalcol == 0
                          ? "-"
                          : new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(row.vlcalcol)}
                      </TableCell>
                      <TableCell align="right">
                        {row.precoerp == null || row.precoerp == 0
                          ? "-"
                          : new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(row.precoerp)}
                      </TableCell>
                      <TableCell align="right">
                        {row.descontoerp == null || row.descontoerp == 0
                          ? "-"
                          : new Intl.NumberFormat("pt-BR", {
                              style: "decimal",
                              currency: "BLR",
                              minimumFractionDigits: 2,
                            }).format(row.descontoerp)}
                      </TableCell>
                      <TableCell align="right">
                        {row.vlcalcerp == null || row.vlcalcerp == 0
                          ? "-"
                          : new Intl.NumberFormat("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            }).format(row.vlcalcerp)}
                      </TableCell>
                      <TableCell align="center">
                        {row.obs == null || row.obs == " "
                          ? " - "
                          : Parser(row.obs)}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "#ffb74d" }}
                          onClick={() => {
                            handleOpen(row.qtatend, row.orderId, row.uuid, 1);
                          }}
                        >
                          <FaEdit />
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "#e57373" }}
                          onClick={() => {
                            handleOpen(row.qtatend, row.orderId, row.uuid, 2);
                          }}
                        >
                          <FaTrash />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ModalContent>
            <Paper style={{ borderRadius: "10px" }}>
              <ModalTitle>Editar Item</ModalTitle>
              <ModalBody>
                <div>{msgModal}</div>
                {modalBody}
                <ModalFooter>
                  <div>{modalBtnAcao}</div>
                  <div>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#ECF0F1" }}
                      size="small"
                      onClick={() => handleClose()}
                    >
                      Cancelar
                    </Button>
                  </div>
                </ModalFooter>
              </ModalBody>
            </Paper>
          </ModalContent>
        </Modal>
      </Paper>
    </Template>
  );
}

const ModalContent = styled.div`
  max-width: 512px;
  margin: auto;
  margin-top: 10%;
  border: solid 1.5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const ModalBody = styled.div`
  background-color: #fff;
  padding: 20px 20px;
`;

const ModalTitle = styled.div`
  background-color: #4791db;
  color: #fff;
  text-align: center;
  /* border-radius: 5px; */
  padding: 10px 10px;
  font-size: 22px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  margin-top: 10px;
  & > div {
    padding: 2px;
  }
`;
