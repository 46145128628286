import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        marginTop: 50
    },
    paper: {
        height: 140,
        width: 100,
        padding: 20
    },
    control: {
        padding: theme.spacing(2),
    },
    itemAlignRight: {
        textAlign: 'right'
    }
}));

export default function EsqueciMinhaSenha({ history }) {
    const classes = useStyles();
    return (
        <>
            {/* <CssBaseline /> */}
            <Container maxWidth="sm" className={classes.container}>
                <Paper className={classes.control}>
                    <h2 className="text-center">Login</h2>
                    <form className={classes.root} noValidate autoComplete="off">
                        <Grid container spacing={3} >
                            <Grid item xs={12} border={1}>
                                <TextField
                                    id="outlined-basic"
                                    label="E-mail"
                                    type="email"
                                    variant="outlined"
                                    fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-password-input"
                                    label="Nova senha"
                                    type="password"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-password-input"
                                    label="Repita senha"
                                    type="password"
                                    autoComplete="current-password"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" fullWidth >
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </>
    )
}