import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { apiFarmix } from '../../../services/api';
import { Template } from '../../components/template/index';
import styled from "styled-components";
import Button from '@material-ui/core/Button';
import { FaDotCircle, FaEraser, FaExternalLinkAlt, FaTrash, FaFileUpload } from 'react-icons/fa';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Parser from 'html-react-parser';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ModalReenvio } from './../../components/modal'
function createData(id, codfilial, numpedol, numpederp, dtimportacao, dtretorno, dtpedido, dtfaturamento, obs, uuid) {
    return { id, codfilial, numpedol, numpederp, dtimportacao, dtretorno, dtpedido, dtfaturamento, obs, uuid };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'id', numeric: true, disablePadding: true, label: 'ID ' },
    { id: 'codfilial', numeric: true, disablePadding: false, label: 'Filial ' },
    { id: 'numpedol', numeric: true, disablePadding: false, label: 'Nº OL ' },
    { id: 'numpederp', numeric: true, disablePadding: false, label: 'Nº Pedido ERP ' },
    { id: 'dtimportacao', numeric: false, disablePadding: false, label: 'Dt Importação ' },
    { id: 'dtretorno', numeric: false, disablePadding: false, label: 'Dt Retorno ' },
    { id: 'dtpedido', numeric: false, disablePadding: false, label: 'Dt Pedido ' },
    { id: 'dtfaturamento', numeric: false, disablePadding: false, label: 'Dt Faturamento ' },
    { id: 'obs', numeric: true, disablePadding: false, label: 'Observação ' },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span classID={classes.visuallyHidden}>
                                    {order === 'desc' ? '' : ''}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell>
                    <TableSortLabel>
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel>
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel>
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    // numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

// const EnhancedTableToolbar = (props) => {
//     const classes = useToolbarStyles();
//     const { numSelected } = props;

//     return (
//         <Toolbar
//             classID={clsx(classes.root, {
//                 [classes.highlight]: numSelected > 0,
//             })}
//         >
//         </Toolbar>
//     );
// };

// EnhancedTableToolbar.propTypes = {
//     numSelected: PropTypes.number.isRequired,
// };

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
        paddingLeft: '5px'
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('cnpj');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState('small');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);
    const [tabelaBaseFiltro, setTabelaBaseFiltro] = React.useState([])
    const caixaDeTexto = React.useRef(null);
    const [nomeOL, setNomeOL] = React.useState('');
    const [statusPedido, setStatusPedido] = React.useState('');
    const listarPedidos = async () => {
        let id = props.match.params.cliente;
        let rota = `/v1/Order/${id}`;
        if (props.match.params.status == 'pendentes') {
            rota = `/v1/Order/status/${id}/1`;
            setStatusPedido('Pendentes');
        } else if (props.match.params.status == 'em-analise') {
            rota = `/v1/Order/status/${id}/2`;
            setStatusPedido('Em Análise');
        } else if (props.match.params.status == 'nao-faturados') {
            rota = `/v1/Order/status/${id}/3`;
            setStatusPedido('Não Faturados');
        } else if (props.match.params.status == 'cancelados') {
            rota = `/v1/Order/status/${id}/4`;
            setStatusPedido('Cancelados');
        }
        let resposta = await apiFarmix('get', rota, null);
        if (resposta.data) {
            let linhasNovas = [];
            resposta.data.results.forEach(item => {
                if (item.dtimportacao == '' || moment(item.dtimportacao).format('DD/MM/Y HH:mm').toString() == 'Invalid date') {
                    item.dtimportacao = ' - ';
                } else {
                    item.dtimportacao = moment(item.dtimportacao).format('DD/MM/Y HH:mm').toString()
                }
                if (item.dtretorno == '' || moment(item.dtretorno).toString() == 'Invalid date') {
                    item.dtretorno = ' - ';
                } else {
                    item.dtretorno = moment(item.dtretorno).format('DD/MM/Y HH:mm').toString()
                }

                if (item.dtpedido == '' || moment(item.dtpedido).format('DD/MM/Y HH:mm').toString() == 'Invalid date') {
                    item.dtpedido = ' - ';
                } else {
                    item.dtpedido = moment(item.dtpedido).format('DD/MM/Y HH:mm').toString()
                }

                if (item.dtfaturamento == '' || moment(item.dtfaturamento).format('DD/MM/Y HH:mm').toString() == 'Invalid date') {
                    item.dtfaturamento = ' - ';
                } else {
                    item.dtfaturamento = moment(item.dtfaturamento).format('DD/MM/Y HH:mm').toString()
                }
                linhasNovas.push(
                    createData(item.id, item.codfilial, item.numpedol, item.numpederp, item.dtimportacao, item.dtretorno, item.dtpedido, item.dtfaturamento, item.obs, item.uuid)
                );
            });
            setTabelaBaseFiltro(linhasNovas);
            setRows(linhasNovas);
        }
    }
    React.useEffect(() => {
        let id = props.match.params.cliente;
        let nome = props.match.params.nome;
        setNomeOL(nome);

        listarPedidos();
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


    const filtrarTabela = async (texto) => {
        let nova = [];
        if (texto.length > 0 && texto != '' && texto != ' ') {
            // console.log(`texto: ${texto}`)
            texto = texto.toLowerCase()
            // console.log(rows)
            tabelaBaseFiltro.forEach(item => {
                if (
                    item.id.toString().search(texto) >= 0 ||
                    item.numpedol.toString().search(texto) >= 0 ||
                    item.numpederp.toString().search(texto) >= 0
                ) {
                    nova.push({
                        "id": item.id,
                        "codfilial": item.codfilial,
                        "numpedol": item.numpedol,
                        "numpederp": item.numpederp,
                        "dtimportacao": (item.dtimportacao),
                        "dtretorno": (item.dtretorno),
                        "dtpedido": (item.dtpedido),
                        "dtfaturamento": (item.dtfaturamento),
                        "obs": item.obs
                    });
                }
            });
            setRows(nova);
        } else {
            let id = props.match.params.cliente;
            let resposta = await apiFarmix('get', `/v1/Order/${id}`, null);
            if (resposta.data) {
                // setRows(JSON.parse(JSON.stringify(resposta.data.results)))
                let linhasNovas = [];
                resposta.data.results.forEach(item => {
                    if (item.dtimportacao == '' || moment(item.dtimportacao).format('DD/MM/Y HH:mm').toString() == 'Invalid date') {
                        item.dtimportacao = ' - ';
                    } else {
                        item.dtimportacao = moment(item.dtimportacao).format('DD/MM/Y HH:mm').toString()
                    }
                    if (item.dtretorno == '' || moment(item.dtretorno).toString() == 'Invalid date') {
                        item.dtretorno = ' - ';
                    } else {
                        item.dtretorno = moment(item.dtretorno).format('DD/MM/Y HH:mm').toString()
                    }

                    if (item.dtpedido == '' || moment(item.dtpedido).format('DD/MM/Y HH:mm').toString() == 'Invalid date') {
                        item.dtpedido = ' - ';
                    } else {
                        item.dtpedido = moment(item.dtpedido).format('DD/MM/Y HH:mm').toString()
                    }

                    if (item.dtfaturamento == '' || moment(item.dtfaturamento).format('DD/MM/Y HH:mm').toString() == 'Invalid date') {
                        item.dtfaturamento = ' - ';
                    } else {
                        item.dtfaturamento = moment(item.dtfaturamento).format('DD/MM/Y HH:mm').toString()
                    }
                    linhasNovas.push(
                        createData(item.id, item.codfilial, item.numpedol, item.numpederp, item.dtimportacao, item.dtretorno, item.dtpedido, item.dtfaturamento, item.obs, item.uuid)
                    );
                });
                setRows(linhasNovas);
            }
        }
    }

    const [open, setOpen] = React.useState(false);
    const [msgModal, setMsgModal] = React.useState(false);
    const [modalBody, setModalBody] = React.useState('');
    const [modalBtnAcao, setModalBtnAcao] = React.useState(
        <div>
            <Button variant="contained" style={{ backgroundColor: '#ECF0F1' }} size="small" onClick={() => handleClose()}>
                Cancelar
            </Button>
        </div>
    );
    const handleClose = () => {
        setOpen(false);
    };

    const exibirTypeOfCancel = async (id) => {
        let typeofcancel = await apiFarmix('get', `/v1/Order/typeofcancel/${props.match.params.cliente}`, null);
        if (typeofcancel != false) {
            typeofcancel = typeofcancel.data.results;
            setModalBody(
                <>
                    <Alert severity="warning" style={{ marginBottom: 10 }}>Após exluir o pedido, não será possível recuperar o pedido!</Alert>
                    {
                        [].map.call(typeofcancel, function (informacao) {
                            return (
                                <Box display="flex" key={informacao.id} style={{ padding: 5, marginBottom: 5, border: 'solid 2px #ecf0f1', borderRadius: 5 }}>
                                    <Box flexGrow={1}>
                                        <div style={{ paddingTop: 3, paddingLeft: 3 }}>
                                            {informacao.description}
                                        </div>
                                    </Box>
                                    <Box>
                                        <Button variant="contained" color="primary" onClick={() => exibirReasontoCancel(id, informacao.id)}>
                                            <FaDotCircle style={{ fontSize: 18 }} />
                                        </Button>
                                    </Box>
                                </Box>
                            );
                        })
                    }
                </>
            )
        }
    }

    const exibirReasontoCancel = async (id, typeofcancel) => {
        let reasontocancel = await apiFarmix('get', `/v1/Order/reasontocancel/${props.match.params.cliente}`, null);
        if (reasontocancel != false) {
            reasontocancel = reasontocancel.data.results;
            setModalBody(
                <>
                    <Alert severity="warning" style={{ marginBottom: 10 }}>Após exluir o pedido, não será possível recuperar o pedido!</Alert>
                    {
                        [].map.call(reasontocancel, function (informacao) {
                            return (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    key={informacao.id}
                                    style={{
                                        padding: 5,
                                        marginBottom: 5,
                                        border: 'solid 2px #ecf0f1',
                                        borderRadius: 5,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => excluirPedido(id, typeofcancel, informacao.id)}>
                                    <Box p={1}>
                                        <div
                                            style={{
                                                paddingTop: 5,
                                                cursor: 'pointer'
                                            }}>
                                            <FaDotCircle className="text-secondary" style={{ fontSize: 18 }} />
                                        </div>
                                    </Box>
                                    <Box p={1} flexGrow={1}>
                                        <div style={{ paddingTop: 3, paddingLeft: 3 }}>
                                            {informacao.description}
                                        </div>
                                    </Box>
                                </Box>
                            );
                        })
                    }
                </>
            )
        }
    }

    const handleOpen = async (qtdSolicitada, id, uuid, tipo) => {
        setMsgModal('');
        await exibirReasontoCancel(id, 2)
        setOpen(true);
    };

    const excluirPedido = async (orderid, typeofcancel, reasonid) => {
        setModalBtnAcao('');
        let resposta = await apiFarmix('put', `/v1/Order/cancel`, {
            "orderid": orderid,
            "typeofcancel": typeofcancel,
            "reasontocancelid": reasonid,
            "userid": parseInt(localStorage.getItem('fb70004dd183ab753b99cd98543eb6c5_userid'))
        });
        // console.log(JSON.stringify({
        //     "orderid": orderid,
        //     "typeofcancel": typeofcancel,
        //     "reasontocancelid": reasonid
        // }))
        // console.log('resposta')
        // console.log(resposta?.response?.status)
        // console.log('resposta')
        setModalBody(
            <>
                <div style={{ textAlign: 'center', padding: '10px', marginTop: '10px' }}>
                    <CircularProgress color="secondary" />
                    <h3 style={{ textAlign: 'center', padding: '10px', marginTop: '10px' }}>
                        Cancelando pedido...
                    </h3>
                </div>
            </>
        )

        if (resposta != undefined && (
            resposta?.status == 400 || resposta?.response?.status == 400 ||
            resposta?.status == 401 || resposta?.response?.status == 401 ||
            resposta?.status == 403 || resposta?.response?.status == 403
        )) {
            let status = resposta?.status === undefined ? resposta?.response?.status : resposta?.response?.status;
            let mensagemErro = '';
            if (
                resposta?.response?.data?.suggestion === undefined &&
                resposta?.response?.data?.suggestion === '' &&
                status !== 403
            ) {
                mensagemErro = 'Ops! Ocorreu um erro';
            } else if (status === 403) {
                mensagemErro = 'Ops! Você não tem permissão';
            } else if (
                resposta?.response?.data?.suggestion !== undefined &&
                resposta.response.data.suggestion !== '' &&
                resposta?.response?.data?.suggestion?.length > 0
            ) {
                mensagemErro = `
                    ${(resposta.response.data.suggestion ??  resposta.response.data.suggestion)} 
                    ${(resposta.response.data.description ??  resposta.response.data.description)} 
                `;
            } else {
                mensagemErro = 'Ops! Ocorreu um erro'
            }
            setModalBody('');
            setMsgModal(
                <Alert severity="warning" style={{ marginBottom: 25 }}>
                    {mensagemErro}
                </Alert>
            );
            setModalBtnAcao(
                <div>
                    <Button variant="contained" style={{ backgroundColor: '#ECF0F1' }} size="small" onClick={() => handleClose()}>
                        Fechar
                    </Button>
                </div>
            );
        } else {
            await listarPedidos();
            handleClose();
        }

    }

    const [openModalReenvio, setOpenModalReenvio] = React.useState(false)
    const [uuidSelecionado, setUuidSelecionado] = React.useState('')

    return (
        <>
            <Template titulo={'Lista de Pedidos ' + statusPedido} nomeOL={nomeOL}>
                <Paper padding={10} style={{ padding: '10px', marginBottom: '15px' }}>
                    {/* <TituloFiltro>
                    Filtro Avançado
                </TituloFiltro> */}
                    <TextField
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        onKeyUp={evt => filtrarTabela(evt.target.value)}
                        onKeyPress={evt => filtrarTabela(evt.target.value)}
                        placeholder="Pesquise aqui..."
                        inputProps={{ ref: caixaDeTexto }}
                    />
                    <Button
                        variant="contained"
                        style={
                            {
                                marginLeft: '10px',
                                padding: '12px',
                                background: '#FAF9F9'
                            }
                        }
                        onClick={() => {
                            caixaDeTexto.current.value = '';
                        }}>
                        <FaEraser />
                    </Button>
                    {/* <Button variant="contained" color="primary" style={{ marginLeft: '10px' }}>
                        Filtro Avançado
                    </Button> */}
                </Paper>
                <div classID={classes.root}>
                    <Paper elevation={1} style={{ padding: 15 }}>
                        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                        <TableContainer>
                            <Table
                                classID={classes.table}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    // numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                />
                                <TableBody>
                                    {stableSort(rows, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.id)}
                                                    role="checkbox"
                                                    // aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                // selected={isItemSelected}
                                                >
                                                    {/* <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell> */}
                                                    <TableCell component="th" id={labelId} scope="row" padding="none" align="center">
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell align="right">{row.codfilial}</TableCell>
                                                    <TableCell align="right">{row.numpedol}</TableCell>
                                                    <TableCell align="right">{row.numpederp}</TableCell>
                                                    <TableCell align="center">{row.dtimportacao}</TableCell>
                                                    <TableCell align="center">{row.dtretorno}</TableCell>
                                                    <TableCell align="center">{row.dtpedido}</TableCell>
                                                    <TableCell align="center">{row.dtfaturamento}</TableCell>
                                                    <TableCell align="center">{row.obs == null || row.obs == ' ' ? ' - ' : Parser(row.obs)}</TableCell>
                                                    <TableCell align="center">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={
                                                                () => {
                                                                    setUuidSelecionado(row.uuid);
                                                                    setOpenModalReenvio(true);
                                                                }
                                                            }>
                                                            <FaFileUpload style={{ color: '#fff' }} />
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Link to={'/dashboard/pedidos/detalhe/' + row.id}>
                                                            <Button variant="contained" color="primary">
                                                                <FaExternalLinkAlt />
                                                            </Button>
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Button variant="contained" style={{ backgroundColor: '#e57373', color: '#fff' }} onClick={() => handleOpen(row.qtsol, row.id, row.uuid)}>
                                                            <FaTrash />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <ModalContent>
                            <Paper style={{ borderRadius: '10px' }}>
                                <ModalTitle>
                                    Excluir Pedido
                                </ModalTitle>
                                <ModalBody>
                                    <div>{msgModal}</div>
                                    {modalBody}
                                    <ModalFooter>
                                        {modalBtnAcao}
                                    </ModalFooter>
                                </ModalBody>
                            </Paper>
                        </ModalContent>
                    </Modal>

                    <ModalReenvio
                        openModalReenvio={openModalReenvio}
                        setOpenModalReenvio={setOpenModalReenvio}
                        uuid={uuidSelecionado}
                    />
                </div>
            </Template>
        </>
    );
}

const ModalTitulo = styled.h1`
background-color: #64b5f6;
color: #fff;
text-align: center;
margin: 0px;
border-radius: 0.25rem;
padding: 10px 5px;
`;

const ModalContent = styled.div`
                    max-width: 512px;
                    margin: auto;
                    margin-top: 10%;
                    border: solid 1.5px rgba(0,0,0,0.1);
                    border-radius: 5px;
                    `;

const ModalBody = styled.div`
                    background-color: #fff;
                    padding: 20px 20px;
                    `;

const ModalTitle = styled.div`
                    background-color: #4791db;
                    color: #fff;
                    text-align: center;
                    /* border-radius: 5px; */
                    padding: 10px 10px;
                    font-size: 22px;
                    `;

const ModalFooter = styled.div`
                    display: flex;
                    justify-content: flex-end;
                    padding-top: 10px;
                    margin-top: 10px;
                    &>div {
                        padding: 2px
                    }
                    `;