import { DialerSip } from "@material-ui/icons";

export const mascaraDocumento = (documento) => {
    let contador = 0;
    let documentoComMascara = '';
    for (let index = 0; index < documento.length; index++) {
        const numero = documento[index];
        contador++;
        documentoComMascara += numero;
        if (contador == 2) {
            documentoComMascara += '.';
        } else if (contador == 5) {
            documentoComMascara += '.';
        } else if (contador == 8) {
            documentoComMascara += '/';
        } else if (contador == 12) {
            documentoComMascara += '-';
        }
    }
    return documentoComMascara;
}

export const mascaraData = (data) => {
    if (data != undefined) {
        let contador = 0;
        let ano = '';
        let mes = '';
        let dia = '';
        for (let index = 0; index < data.length; index++) {
            const texto = data[index];
            contador++;
            if (contador <= 4) {
                ano += texto;
            } else if (contador < 8 && contador > 5) {
                mes += texto;
            } else if (contador > 8 && contador <= 10) {
                dia += texto;
            }
        }
        return `${dia}/${mes}/${ano}`;
    } else {
        return '-';
    }

}

export const retornandoSomenteInteiro = (numero) => {
    let resultado = '';
    for (let index = 0; index < numero.length; index++) {
        if (
            numero[index] == '0' ||
            numero[index] == '1' ||
            numero[index] == '2' ||
            numero[index] == '3' ||
            numero[index] == '4' ||
            numero[index] == '5' ||
            numero[index] == '6' ||
            numero[index] == '7' ||
            numero[index] == '8' ||
            numero[index] == '9'
        ) {
            resultado += numero[index];
        }
    }
    return resultado;
}

